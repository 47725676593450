import { Box, Grid, Paper, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useGetAuthUserQuery } from "../../../store/user/userApis";
import React, { useEffect } from "react";
import { useTheme } from "@emotion/react";
import { ProfileForm } from "../../../components/admin/profile/ProfileForm";
import { ChangePasswordForm } from "../../../components/admin/profile/ChangePasswordForm";
import { isAuth } from "../../../helpers/cookies";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const ProfileContainer = () => {
    const theme = useTheme();
    const authData = isAuth();
    const { isLoading, data } = useGetAuthUserQuery();
    const [currentTab, setCurrentTab] = React.useState(0);

    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    return (
        <Stack spacing={1}>
            <Paper sx={{ marginBottom: "24px", padding: 1.5 }}>
                <Grid container justifyContent="space-between">
                    <Grid item sx={{ alignSelf: "center" }}>
                        <Typography variant="h4">Settings</Typography>
                    </Grid>
                </Grid>
            </Paper>

            <Paper sx={{ marginBottom: "24px", padding: 1.5 }}>
                <Grid container justifyContent="space-between">
                    <Grid item xs={12}>
                        <Tabs
                            value={currentTab}
                            onChange={handleChange}
                            aria-label="wrapped label tabs example"
                        >
                            <Tab value={0} label="Profile" />
                            <Tab value={1} label="Change Password" />
                        </Tabs>
                    </Grid>
                </Grid>
            </Paper>

            <Paper sx={{ marginBottom: "24px", padding: 1.5 }}>
                <Grid container justifyContent="space-between">
                    <Grid item xs={12}>
                        <TabPanel value={currentTab} index={0} dir={theme.direction}>
                            <ProfileForm initialValue={data} roles={authData?.roles} />
                        </TabPanel>
                        <TabPanel value={currentTab} index={1} dir={theme.direction}>
                            <ChangePasswordForm roles={authData?.roles} user_id={data?.user_id} data={data} />
                        </TabPanel>
                    </Grid>
                </Grid>
            </Paper>
        </Stack >
    );
}

export default ProfileContainer; 