import { Formik } from "formik"
import { FamilyMembersValidation } from "./MembersValidation"
import { Grid } from "@mui/material"
import Input from "../../Common/Input"
import BasicSelect from "../../Common/Select"
import Button from "../../Common/Button"
import DatePickerComponent from "../../Common/DatePicker"
import { formatISO, parseISO } from "date-fns"
import CommonModal from "../../Common/CommonModal"
import { Fragment } from "react"
import StyledTextarea from "../../Common/StyledTextarea"
import { relation } from "../../../helpers/sampleData"

export const FamilyMemberAdd = ({ familyMemberInitalval, submit, qualificationData }) => {
    return (
        <Formik
            initialValues={familyMemberInitalval}
            onSubmit={(values) => submit(values)}
            validationSchema={FamilyMembersValidation}
            enableReinitialize
        >
            {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                setFieldValue
            }) => (
                <Grid sx={{ display: "flex" }} container direction={"column"} width={"100%"} height={"100%"}>
                    <Grid flex={1} px={2} py={2} overflow={"auto"}>
                        <Grid container spacing={2} >
                            <Grid item xs={12} md={6}>
                                <BasicSelect
                                    size="small"
                                    value={values?.relation || ""}
                                    onChange={handleChange("relation")}
                                    displayEmpty
                                    label="Relation"
                                    name="relation"
                                    id="relation"
                                    items={relation.map((element) => ({ label: element, value: element }))}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Input
                                    id='name'
                                    name="name"
                                    label="Name *"
                                    onChange={handleChange("name")}
                                    value={values?.name || ""}
                                    error={Boolean(errors.name)}
                                    helperText={errors.name}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Input
                                    id='mobile'
                                    name="mobile"
                                    label="Mobile"
                                    onChange={handleChange("mobile")}
                                    value={values?.mobile || ""}
                                    error={Boolean(errors.mobile)}
                                    helperText={errors.mobile}
                                    fullWidth
                                />
                            </Grid>
                            <Fragment>
                                <Grid item xs={12} md={6}>
                                    <BasicSelect
                                        size="small"
                                        value={values?.gender || null}
                                        onChange={handleChange("gender")}
                                        displayEmpty
                                        label="Gender"
                                        name="gender"
                                        id="gender"
                                        items={[
                                            { label: "Male", value: "Male" },
                                            { label: "Female", value: "Female" },
                                        ]}
                                    />
                                </Grid>
                            </Fragment>

                            <Grid item xs={12} md={6}>
                                <BasicSelect
                                    size="small"
                                    value={values?.qualification || ""}
                                    onChange={handleChange("qualification")}
                                    displayEmpty
                                    label="Qualification"
                                    name="qualification"
                                    id="qualification"
                                    items={qualificationData || []}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <DatePickerComponent
                                    id={"dob"}
                                    name={"dob"}
                                    label="Date of Birth"
                                    onChange={(val) => setFieldValue("dob", formatISO(val))}
                                    value={values?.dob ? new Date(parseISO(values?.dob)) : null}
                                    fullWidth
                                    onBlur={handleBlur}
                                />
                            </Grid>
                            <Fragment>
                                <Grid item xs={12} md={6}>
                                    <BasicSelect
                                        size="small"
                                        value={values?.married}
                                        onChange={handleChange("married")}
                                        displayEmpty
                                        label="Married?"
                                        name="married"
                                        id="married"
                                        items={[
                                            { label: "Yes", value: "yes" },
                                            { label: "No", value: "no" }
                                        ]}
                                    />
                                </Grid>
                                {
                                    values?.married === "yes" && (
                                        <Grid item xs={12} md={6}>
                                            <DatePickerComponent
                                                id={"marriage_date"}
                                                name={"marriage_date"}
                                                label="Marriage Date"
                                                onChange={(val) => setFieldValue("marriage_date", val)}
                                                value={values?.marriage_date ? new Date(values?.marriage_date) : null}
                                                fullWidth
                                                onBlur={handleBlur}
                                            />
                                        </Grid>
                                    )
                                }
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='blood_group'
                                        name="blood_group"
                                        label="Blood Group"
                                        onChange={handleChange("blood_group")}
                                        value={values?.blood_group || ""}
                                        error={Boolean(errors.blood_group)}
                                        helperText={errors.blood_group}
                                        fullWidth
                                    />
                                </Grid>
                            </Fragment>
                            <Grid item xs={12} md={12}>
                                <StyledTextarea
                                    id='note'
                                    name="note"
                                    minRows={2}
                                    label="Note"
                                    onChange={handleChange("note")}
                                    value={values?.note || ""}
                                    error={Boolean(errors.note)}
                                    helperText={errors.note}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
                                <Button size="large" type="submit" fullWidth={false} loading={false} onClick={() => handleSubmit()}>Save</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Formik>
    )
}