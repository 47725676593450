import React from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

const withNavigate = (Component) => (props) => {
    const navigate = useNavigate();
    const params = useParams();
    const { search } = useLocation();

    const query = new URLSearchParams(search);

    return <Component {...props} navigate={navigate} params={params} search={query} />;
};

export default withNavigate;