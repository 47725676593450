import { useHandleFileUploadMutation } from "../../store/common/commonApis"
import { setSnackBar } from "../../store/common/commonSlice";
import { useDispatch } from "react-redux";
import { FileUploader } from "react-drag-drop-files";
import { baseUrl, fileTypes } from "../../helpers/constants";
import { FormControl, FormHelperText, InputLabel } from "@mui/material";

export const CommonPdfFileUploadToServer = ({ onChange, value, label, error, helperText, labelSecondary = "Upload home page about image right here", types = null }) => {
    const [uploadImage] = useHandleFileUploadMutation();
    const dispatch = useDispatch();

    const handleFileChange = async (file) => {
        try {
            const formData = new FormData();
            formData.append("image", file);
            let result = await uploadImage(formData);
            onChange(result.data.path);
        } catch (error) {
            dispatch(setSnackBar({
                open: true,
                message: error?.data?.message || error.message,
                severity: "error",
            }))
        }
    };

    return (
        <FormControl fullWidth error={error}>
            <InputLabel size={"small"} >{label}</InputLabel>
            <FileUploader
                classes="drop_area"
                handleChange={handleFileChange}
                name="file"
                types={types || fileTypes}
                label={labelSecondary}
            />
            {
                value
                    ?
                    <a href={baseUrl + "/" + value} target="_blank" rel="noreferrer">View</a>
                    :
                    null
            }
            {error ? (
                <FormHelperText>{helperText}</FormHelperText>
            ) : null}
        </FormControl>
    )
}