import { createSlice } from "@reduxjs/toolkit";

export const homeInitialForm = {
    sanstha_id: "",
    home_about_title: "",
    home_about_description: "",
    home_about_image_url: "",
    home_video_thumbnail_url: "",
    home_video_url: "",
    home_video_title: "",
    home_video_description: "",
    home_why_title: "",
    home_why_description: "",
    home_total_tax_percent: "",
    home_total_daan_percent: "",
    home_total_mandir: "",
    home_total_yogdan_percent: "",
    home_why_detail_description: "",
}

export const aboutInitialForm = {
    sanstha_id: "",
    about_title: "",
    about_description: "",
}

export const contactInitialForm = {
    sanstha_id: "",
    address: "",
    email_1: "",
    email_2: "",
    phone_1: "",
    phone_2: "",
}

const initialState = {
    sansthaId: null,
    homeInitialValues: null,
    aboutInitialValues: null,
    contactInitialValues: null,
};

export const cmsSlice = createSlice({
    name: "cms",
    initialState,
    reducers: {
        changeHomeInitialState: (state, action) => {
            const initialValues = action.payload;
            if (initialValues)
                state.homeInitialValues = initialValues;
            else
                state.homeInitialValues = homeInitialForm;
        },
        changeAboutInitialState: (state, action) => {
            const initialValues = action.payload;
            if (initialValues)
                state.aboutInitialValues = initialValues;
            else
                state.aboutInitialValues = aboutInitialForm;
        },
        changeContactInitialState: (state, action) => {
            const initialValues = action.payload;
            if (initialValues)
                state.contactInitialValues = initialValues;
            else
                state.contactInitialValues = contactInitialForm;
        },
        changeSansthaId: (state, action) => {
            state.sansthaId = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { changeHomeInitialState, changeAboutInitialState, changeContactInitialState, changeSansthaId } = cmsSlice.actions;

export default cmsSlice;
