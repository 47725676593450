import { Grid } from "@mui/material";
import React from "react";
import { CardSummary } from "../../../components/Common/CardSummary";
import { useGetDashboardCountQuery } from "../../../store/common/commonApis";
import DashboardSkeleton from "../../../components/Common/DashboardSkeleton";
import { isAuth } from "../../../helpers/cookies";

const DashboardContainer = () => {
    const userRoles = isAuth().roles;
    const { data, isLoading } = useGetDashboardCountQuery();

    return (
        <>
            {
                isLoading
                    ?
                    <DashboardSkeleton />
                    :
                    <Grid container spacing={3} pb={3}>
                        {
                            userRoles === "admin" && (
                                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                                    <CardSummary
                                        title="Total Number of Sanstha"
                                        value={data.sanstha}
                                    />
                                </Grid>
                            )
                        }
                        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                            <CardSummary
                                title="Total Number of Members"
                                value={data.member}
                            />
                        </Grid>
                        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                            <CardSummary
                                title="Total Number of Candidates"
                                value={data.candidate}
                            />
                        </Grid>
                    </Grid>
            }
        </>
    );
}

export default DashboardContainer;