import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { changeAboutInitialState, changeSansthaId } from "../../../store/cms/cmsSlice";
import sansthaApis from "../../../store/sanstha/sansthaApis";

const mapStateToProps = (state) => {
    return {
        aboutInitialValues: state.cms.aboutInitialValues,
        sansthaId: state.cms.sansthaId,
    };
};

const mapDispatch = {
    changeAboutInitialState,
    changeSansthaId,
    getSansthaList: sansthaApis.endpoints.getSansthaList.initiate,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(mapDispatch, dispatch);

const Store = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;