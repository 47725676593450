import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from "react-redux";

// all api calling using rtk query
import userApis from "./user/userApis";
import sansthaApis from "./sanstha/sansthaApis";
import membersApis from "./members/membersApis";
import mastersApis from "./masters/mastersApis";
import candidateApis from "./candidate/candidateApis";
import commonApis from "./common/commonApis";
import cmsApis from "./cms/cmsApis";

// redux state slices
import commonSlice from "./common/commonSlice";
import userSlice from "./user/userSlice";
import sansthaSlice from "./sanstha/sansthaSlice";
import membersSlice from "./members/membersSlice";
import mastersSlice from "./masters/mastersSlice";
import candidateSlice from "./candidate/candidateSlice";
import cmsSlice from "./cms/cmsSlice";

// axios interceptor
import axiosInterceptor from "../helpers/axios";

const reducers = {
    [commonSlice.name]: commonSlice.reducer,
    [userSlice.name]: userSlice.reducer,
    [sansthaSlice.name]: sansthaSlice.reducer,
    [membersSlice.name]: membersSlice.reducer,
    [mastersSlice.name]: mastersSlice.reducer,
    [candidateSlice.name]: candidateSlice.reducer,
    [cmsSlice.name]: cmsSlice.reducer,

    [userApis.reducerPath]: userApis.reducer,
    [sansthaApis.reducerPath]: sansthaApis.reducer,
    [membersApis.reducerPath]: membersApis.reducer,
    [mastersApis.reducerPath]: mastersApis.reducer,
    [candidateApis.reducerPath]: candidateApis.reducer,
    [commonApis.reducerPath]: commonApis.reducer,
    [cmsApis.reducerPath]: cmsApis.reducer,
};

const initialState = {};

const rootReducer = combineReducers(reducers);

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck: false
        }).concat([
            userApis.middleware,
            sansthaApis.middleware,
            membersApis.middleware,
            mastersApis.middleware,
            candidateApis.middleware,
            commonApis.middleware,
            cmsApis.middleware,
        ]);
    },
    devTools: true,
    preloadedState: initialState,
    enhancers: (defaultEnhancers) => [...defaultEnhancers]
});


export const useAppSelector = () => useSelector(rootReducer);
export const useAppDispatch = () => useDispatch(store.dispatch);

axiosInterceptor(store.dispatch);

export default store;