import React, { useEffect } from "react";
import { Button, Grid, Paper, Stack, Typography } from "@mui/material";
import PhotoGalleryTableComponent from "../../../components/admin/photo-gallery-manager/PhotoGalleryTable";
import { PhotoGalleryAddDrawer } from "../../../components/admin/photo-gallery-manager/PhotoGalleryAddDrawer";
import { SearchRecords } from "../../../components/Common/SearchRecords";
import { useGetGalleryListQuery } from "../../../store/masters/mastersApis";
import { Add } from "@mui/icons-material";

const PhotoGalleryManagerContainer = (props) => {
    const { showDrawer, formType, initialValues, pagination } = props;
    const { isLoading, data: gallery, isFetching } = useGetGalleryListQuery(pagination);

    const handleAddPhotoGallery = () => {
        props.changeMastersInitialState({
            showDrawer: true,
            formType: "Add"
        })
    }

    useEffect(() => {
        props.handlePaginationState({
            pageNo: 0,
            limit: 10,
            sortBy: -1,
            sortField: "createdAt",
            keywords: ""
        })
    }, []);

    const handlePagination = (setting) => {
        props.handlePaginationState(setting);
    }

    const handleDrawerClose = () => {
        props.changeMastersInitialState({ showDrawer: false, formType: "", initialValues: { sanstha_id: null, category: null, type: null, url: null, profile: null } })
    }

    const handlePhotoGalleryEdit = (data, type) => {
        let payload = { ...data };
        delete payload.createdAt;
        props.changeMastersInitialState({ showDrawer: true, formType: type, initialValues: payload })
    }

    return (
        <Stack spacing={1}>
            <Paper sx={{ marginBottom: "24px", padding: 1.5 }}>
                <Grid container justifyContent="space-between">
                    <Grid item sx={{ alignSelf: "center" }}>
                        <Typography variant="h6">List of Photo Gallery</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            disableElevation
                            variant="contained"
                            sx={{ borderRadius: "50px" }}
                            onClick={() => handleAddPhotoGallery()}
                            startIcon={<Add />}
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>
            </Paper>

            <Grid container>
                <SearchRecords handlePagination={handlePagination} pagination={pagination} />
                <Grid item xs={12}>
                    <Paper
                        sx={{ p: 2, color: "#071B2A", fontWeight: "400" }}
                        elevation={0}
                    >
                        <PhotoGalleryTableComponent fetching={isFetching} edit={(val, type) => handlePhotoGalleryEdit(val, type)} loading={isLoading} count={gallery?.count || 0} data={gallery?.result || []} pagination={pagination} handlePagination={(val) => handlePagination(val)} />
                    </Paper>
                </Grid>
            </Grid>

            {/* add taf drawer */}
            <PhotoGalleryAddDrawer getSansthaList={props.getSansthaList} show={showDrawer} close={handleDrawerClose} formType={formType} initialValues={initialValues} />

        </Stack >
    );
}

export default PhotoGalleryManagerContainer;