import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { CandidateValidation } from "./CandidateValidation";
import { Drawer, FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseOutlined"
import IconButtonIcons from "../../Common/IconButtonIcons";
import Input from "../../Common/Input";
import Button from "../../Common/Button";
import { useDispatch } from "react-redux";
import { setSnackBar } from "../../../store/common/commonSlice";
import { useAddNewCandidateMutation, useUpdateCandidateMutation } from "../../../store/candidate/candidateApis";
import BasicSelect from "../../Common/Select";
import { useGetGotraListQuery, useGetQualificationListQuery } from "../../../store/masters/mastersApis";
import { communities, incomeNumber, maritalStatus } from "../../../helpers/sampleData";
import DatePickerComponent from "../../Common/DatePicker";
import TimePickerComponent from "../../Common/TimePicker";
import { FileUploader } from "react-drag-drop-files";
import { fileTypes } from "../../../helpers/constants";
import { SelectHeight } from "../../Common/SelectHeight";
import ImagesGallery from "./ImagesGallery";
import { useGetDropdownSansthaListQuery } from "../../../store/sanstha/sansthaApis";
import AutoCompleteServerSide from "../../Common/AutoCompleteServerSide";


export const CandidateAddDrawer = ({ initialValues, show, close, formType, isSearched = false, getSansthaList }) => {
    const [files, setFiles] = useState([]);
    const [images, setImages] = useState([]);

    useEffect(() => {
        setImages(initialValues?.profile)
    }, [initialValues?.profile])

    const handleFileChange = (evnt) => {
        const selectedFIles = [];
        setFiles(evnt);
        const targetFilesObject = [...evnt];
        targetFilesObject.map((file) => {
            return selectedFIles.push(URL.createObjectURL(file))
        })
        setImages(selectedFIles);
    };

    const dispatch = useDispatch();
    const [addNewCandidate, { isLoading: addCandidateLoading }] = useAddNewCandidateMutation();
    const [updateCandidate, { isLoading: updateCandidateLoading }] = useUpdateCandidateMutation();
    const { data: sansthaList } = useGetDropdownSansthaListQuery({}, { skip: !show });
    const { data: gotraData } = useGetGotraListQuery({ pageNo: 0, limit: 1000, sortBy: -1, sortField: "createdAt", active: true }, { skip: !show });
    const { data: qualificationData } = useGetQualificationListQuery({ pageNo: 0, limit: 1000, sortBy: -1, sortField: "createdAt", active: true }, { skip: !show });

    const disabled = formType === 'View' ? true : false;

    const onFormSubmit = async (values) => {
        try {
            const formData = new FormData();

            Object.keys(values).forEach(function (key, index) {
                if (key === "sanstha_id") {
                    formData.append(key, JSON.stringify(values[key]));
                } else
                    formData.append(key, values[key]);
            });

            for (const file of files) {
                formData.append("images", file);
            }

            if (formType === "Edit") {
                await updateCandidate(values).unwrap();
                dispatch(setSnackBar({
                    open: true,
                    message: "Candidate updated successfully",
                    severity: "success",
                }))
            } else {
                await addNewCandidate(formData).unwrap();
                dispatch(setSnackBar({
                    open: true,
                    message: "Candidate created successfully",
                    severity: "success",
                }))
            }
            close();
        } catch (error) {
            dispatch(setSnackBar({
                open: true,
                message: error?.data?.message || error.message,
                severity: "error",
            }))
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => onFormSubmit(values)}
            validationSchema={CandidateValidation}
            enableReinitialize
        >
            {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                setFieldValue
            }) => (
                <Drawer
                    anchor={"right"}
                    open={show}
                    PaperProps={{
                        sx: { width: { xs: '100%', md: '70%', sm: "70%", lg: "70%" } },
                    }}
                    onClose={() => close()}
                >
                    {console.log(errors, "errors")}
                    <Grid sx={{ display: "flex" }} direction={"column"} width={"100%"} height={"100%"} className="candidate-form" >
                        <Grid container flex={0} px={1} py={1} borderBottom={1} borderColor={"rgba(5, 5, 5, 0.06)"}>
                            <Grid item alignSelf={"center"}>
                                <IconButtonIcons color="default" title="Close" IconComponent={CloseIcon} onClick={() => close()} />
                            </Grid>
                            <Grid item alignSelf={"center"}>
                                <Typography variant="h6">{formType} Candidates</Typography>
                            </Grid>
                        </Grid>
                        <Grid flex={1} px={2} py={5} overflow={"auto"}>
                            <Grid container spacing={2} >
                                <Grid item xs={12} md={12}>
                                    <Typography variant="h6">Personal Details</Typography>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    {
                                        formType !== "View"
                                            ?
                                            <FileUploader
                                                classes="drop_area"
                                                handleChange={handleFileChange}
                                                name="file"
                                                types={fileTypes}
                                                label="Upload or drop all files at once right here"
                                                multiple={true}
                                            />
                                            :
                                            null
                                    }

                                    <ImagesGallery images={images} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <AutoCompleteServerSide
                                        label="Type & Select Sanstha *"
                                        name="sanstha_id"
                                        id="sanstha_id"
                                        fullWidth
                                        fetchDataFunction={(d) => getSansthaList(d)}
                                        onChange={(val) => setFieldValue("sanstha_id", val)}
                                        defaultValue={values?.sanstha_id || null}
                                        error={Boolean(errors.sanstha_id)}
                                        helperText={errors.sanstha_id}
                                        isMultiple={true}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='name'
                                        name="name"
                                        label="Name"
                                        onChange={handleChange("name")}
                                        value={values?.name || ""}
                                        error={Boolean(errors.name)}
                                        helperText={errors.name}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='email'
                                        name="email"
                                        label="Email"
                                        onChange={handleChange("email")}
                                        value={isSearched ? values?.email.replace(/(\w{1})(.*)(\w{1})@(.*)/, '$1******$3@$4') : values?.email || ""}
                                        error={Boolean(errors.email)}
                                        helperText={errors.email}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='mobile'
                                        name="mobile"
                                        label="Mobile / Whatsapp No."
                                        onChange={handleChange("mobile")}
                                        value={isSearched ? values?.mobile.replace(/(\d{1})(.*)(\d{3})/, '$1******$3') : values?.mobile || ""}
                                        error={Boolean(errors.mobile)}
                                        helperText={errors.mobile}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BasicSelect
                                        size="small"
                                        value={values?.gender || "male"}
                                        onChange={handleChange("gender")}
                                        displayEmpty
                                        label="Gender"
                                        name="gender"
                                        id="gender"
                                        items={[
                                            { label: "Male", value: "male" },
                                            { label: "Female", value: "female" },
                                        ]}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DatePickerComponent
                                        id={"dob"}
                                        name={"dob"}
                                        label="Date of Birth"
                                        onChange={(val) => setFieldValue("dob", val)}
                                        value={values?.dob ? new Date(values?.dob) : null}
                                        fullWidth
                                        disabled={disabled}
                                        onBlur={handleBlur}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TimePickerComponent
                                        id={"tob"}
                                        name={"tob"}
                                        label="Time of Birth"
                                        onChange={(val) => setFieldValue("tob", val)}
                                        value={values?.tob ? new Date(values?.tob) : null}
                                        fullWidth
                                        disabled={disabled}
                                        onBlur={handleBlur}
                                        ampm={false}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='birth_place'
                                        name="birth_place"
                                        label="Birth Place"
                                        onChange={handleChange("birth_place")}
                                        value={values?.birth_place || ""}
                                        error={Boolean(errors.birth_place)}
                                        helperText={errors.birth_place}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <SelectHeight
                                        id='height'
                                        name="height"
                                        label="Height (Feet)"
                                        onChange={(val) => setFieldValue("height", val)}
                                        value={values?.height || ""}
                                        error={Boolean(errors.height)}
                                        helperText={errors.height}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='weight'
                                        name="weight"
                                        label="Weight (kg)"
                                        onChange={handleChange("weight")}
                                        value={values?.weight || ""}
                                        error={Boolean(errors.weight)}
                                        helperText={errors.weight}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='colour'
                                        name="colour"
                                        label="Skin Colour"
                                        onChange={handleChange("colour")}
                                        value={values?.colour || ""}
                                        error={Boolean(errors.colour)}
                                        helperText={errors.colour}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='native_place'
                                        name="native_place"
                                        label="Native Place"
                                        onChange={handleChange("native_place")}
                                        value={values?.native_place || ""}
                                        error={Boolean(errors.native_place)}
                                        helperText={errors.native_place}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BasicSelect
                                        size="small"
                                        value={values?.religion || ""}
                                        onChange={handleChange("religion")}
                                        displayEmpty
                                        label="Religion"
                                        name="religion"
                                        id="religion"
                                        items={[
                                            { label: "Hindu", value: "Hindu" },
                                            { label: "Jain", value: "Jain" },
                                        ]}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BasicSelect
                                        size="small"
                                        value={values?.mother_tongue || ""}
                                        onChange={handleChange("mother_tongue")}
                                        displayEmpty
                                        label="Mother Tongue"
                                        name="mother_tongue"
                                        id="mother_tongue"
                                        items={[
                                            { label: "Marwadi", value: "Marwadi" },
                                        ]}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BasicSelect
                                        size="small"
                                        value={values?.communities || ""}
                                        onChange={handleChange("communities")}
                                        displayEmpty
                                        label="Communities"
                                        name="communities"
                                        id="communities"
                                        items={communities}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='hobbies'
                                        name="hobbies"
                                        label="Hobbies"
                                        onChange={handleChange("hobbies")}
                                        value={values?.hobbies || ""}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BasicSelect
                                        size="small"
                                        value={values?.manglic || "false"}
                                        onChange={handleChange("manglic")}
                                        displayEmpty
                                        label="Manglic or Non Manglic"
                                        name="manglic"
                                        id="manglic"
                                        items={[
                                            { label: "Manglic", value: "true" },
                                            { label: "Non Manglic", value: "false" },
                                        ]}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BasicSelect
                                        size="small"
                                        value={values?.matching_horoscope || "false"}
                                        onChange={handleChange("matching_horoscope")}
                                        displayEmpty
                                        label="Matching Horoscope"
                                        name="matching_horoscope"
                                        id="matching_horoscope"
                                        items={[
                                            { label: "Yes", value: "true" },
                                            { label: "No", value: "false" },
                                        ]}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography variant="h6">Family Details</Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='father_name'
                                        name="father_name"
                                        label="Father Name"
                                        onChange={handleChange("father_name")}
                                        value={values?.father_name || ""}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BasicSelect
                                        size="small"
                                        value={values?.father_gotra || ""}
                                        onChange={handleChange("father_gotra")}
                                        displayEmpty
                                        label="Father Gotra"
                                        name="father_gotra"
                                        id="father_gotra"
                                        items={gotraData || []}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='mother_name'
                                        name="mother_name"
                                        label="Mother Name"
                                        onChange={handleChange("mother_name")}
                                        value={values?.mother_name || ""}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BasicSelect
                                        size="small"
                                        value={values?.mother_gotra || ""}
                                        onChange={handleChange("mother_gotra")}
                                        displayEmpty
                                        label="Mother Gotra"
                                        name="mother_gotra"
                                        id="mother_gotra"
                                        items={gotraData || []}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='number_of_brother'
                                        name="number_of_brother"
                                        label="Number of Brothers"
                                        onChange={handleChange("number_of_brother")}
                                        value={values?.number_of_brother || ""}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='number_of_sister'
                                        name="number_of_sister"
                                        label="Number of Sisters"
                                        onChange={handleChange("number_of_sister")}
                                        value={values?.number_of_sister || ""}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='address_of_residence'
                                        name="address_of_residence"
                                        label="Address of Residence"
                                        onChange={handleChange("address_of_residence")}
                                        value={values?.address_of_residence || ""}
                                        error={Boolean(errors.address_of_residence)}
                                        helperText={errors.address_of_residence}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='financial_budget'
                                        name="financial_budget"
                                        label="Financial Budget"
                                        onChange={handleChange("financial_budget")}
                                        value={values?.financial_budget || ""}
                                        error={Boolean(errors.financial_budget)}
                                        helperText={errors.financial_budget}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography variant="h6">Qualification Details</Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BasicSelect
                                        size="small"
                                        value={values?.qualification || ""}
                                        onChange={handleChange("qualification")}
                                        displayEmpty
                                        label="Qualification"
                                        name="qualification"
                                        id="qualification"
                                        items={qualificationData || []}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BasicSelect
                                        size="small"
                                        value={values?.employeed || ""}
                                        onChange={handleChange("employeed")}
                                        displayEmpty
                                        label="Employeed"
                                        name="employeed"
                                        id="employeed"
                                        items={[
                                            { label: "Own Business", value: "Own Business" },
                                            { label: "Govt Job", value: "Govt Job" },
                                            { label: "Private Company", value: "Private Company" },
                                            { label: "Public", value: "Public" },
                                        ]}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='business_company_name'
                                        name="business_company_name"
                                        label="Business or Company Name"
                                        onChange={handleChange("business_company_name")}
                                        value={values?.business_company_name || ""}
                                        error={Boolean(errors.business_company_name)}
                                        helperText={errors.business_company_name}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BasicSelect
                                        size="small"
                                        value={values?.annual_income || ""}
                                        onChange={handleChange("annual_income")}
                                        displayEmpty
                                        label="Annual Income"
                                        name="annual_income"
                                        id="annual_income"
                                        items={incomeNumber()}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='address_of_business'
                                        name="address_of_business"
                                        label="Business or Company Address"
                                        onChange={handleChange("address_of_business")}
                                        value={values?.address_of_business || ""}
                                        error={Boolean(errors.address_of_business)}
                                        helperText={errors.address_of_business}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography variant="h6">Other Details</Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BasicSelect
                                        size="small"
                                        value={values?.marital_status || "N/A"}
                                        onChange={handleChange("marital_status")}
                                        displayEmpty
                                        label="Marital Status"
                                        name="marital_status"
                                        id="marital_status"
                                        items={maritalStatus}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='reffered_by'
                                        name="reffered_by"
                                        label="Reffered By"
                                        onChange={handleChange("reffered_by")}
                                        value={values?.reffered_by || ""}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='city'
                                        name="city"
                                        label="City"
                                        onChange={handleChange("city")}
                                        value={values?.city || ""}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='state'
                                        name="state"
                                        label="State"
                                        onChange={handleChange("state")}
                                        value={values?.state || ""}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='zipcode'
                                        name="zipcode"
                                        label="Zipcode"
                                        onChange={handleChange("zipcode")}
                                        value={values?.zipcode || ""}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BasicSelect
                                        size="small"
                                        value={values?.status || "true"}
                                        onChange={handleChange("status")}
                                        displayEmpty
                                        label="Active"
                                        name="status"
                                        id="status"
                                        items={[
                                            { label: "Active", value: "true" },
                                            { label: "In-active", value: "false" },
                                        ]}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography variant="h6">Setting</Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControlLabel control={<Switch checked={values?.show_to_everyone} />} label="Show to everyone" onChange={(e) => setFieldValue("show_to_everyone", e.target.checked)} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControlLabel control={<Switch checked={values?.show_limited} />} label="Show Limited Records Only" onChange={(e) => setFieldValue("show_limited", e.target.checked)} />
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                            formType !== 'View'
                                ?
                                <Grid flexShrink={0} borderTop={1} borderColor={"rgba(152, 188, 252, 0.16)"} sx={{ padding: "8px 16px" }}>
                                    <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Grid sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                            <Button size="large" color="warning" type="button" onClick={() => close()}>Cancel</Button>
                                            <Button size="large" type="submit" loading={addCandidateLoading || updateCandidateLoading} onClick={() => handleSubmit()}>Save</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                :
                                null
                        }

                    </Grid>
                </Drawer>
            )
            }
        </Formik >
    )
}