import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from "../../helpers/constants";
import { getCookie } from "../../helpers/cookies";
import { userListParser } from './userParser';

const userApis = createApi({
    reducerPath: 'userApis',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getCookie("token");
            if (token) {
                headers.set('Authorization', `BEARER ${token}`)
            }
            return headers;
        }
    }),
    keepUnusedDataFor: 0,
    tagTypes: ["User"],
    endpoints: build => ({
        getUserList: build.query({
            query: (params) => ({
                url: "/auth/read-users",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => userListParser(response),
            providesTags: ['User'],
            keepUnusedDataFor: 5,
        }),
        addNewUser: build.mutation({
            query: (payload) => ({
                url: "/auth/register",
                method: "POST",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['User'],
        }),
        updateUser: build.mutation({
            query: (payload) => ({
                url: "/auth/update-users",
                method: "PUT",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['User'],
        }),
        convertToUser: build.mutation({
            query: (payload) => ({
                url: "/auth/convert-to-user",
                method: "POST",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['User'],
        }),
        deleteUser: build.mutation({
            query: (params) => ({
                url: "/auth/users",
                method: "DELETE",
                params: params
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['User'],
        }),
        getAuthUser: build.query({
            query: () => ({
                url: "/auth/me",
                method: "GET",
            }),
            transformResponse: (response) => response.result,
            keepUnusedDataFor: 5,
        }),
    }),
});

export const { useGetUserListQuery, useAddNewUserMutation, useUpdateUserMutation, useConvertToUserMutation, useDeleteUserMutation, useGetAuthUserQuery } = userApis;
export default userApis;