import React, { useState } from "react";
import { Formik } from "formik";
import { Grid, IconButton, InputAdornment } from "@mui/material";
import Button from "../../Common/Button.jsx";
import { useDispatch } from "react-redux";
import { setSnackBar } from "../../../store/common/commonSlice.js";
import Input from "../../Common/Input.jsx";
import { PasswordValidation } from "./validation.js";
import { useUpdateUserMutation } from "../../../store/user/userApis.js";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export const ChangePasswordForm = ({ user_id, roles, data }) => {
    const dispatch = useDispatch();
    const [updateUser] = useUpdateUserMutation();
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const onFormSubmit = async (values, { resetForm }) => {
        try {
            let payload = {
                _id: user_id,
                password: values.new_password,
                name: data?.name,
                email: data?.email,
                mobile: data?.mobile
            }
            await updateUser(payload);
            resetForm();
            dispatch(setSnackBar({
                open: true,
                message: "Password Changed Sucessfully",
                severity: "success",
            }))
        } catch (error) {
            dispatch(setSnackBar({
                open: true,
                message: error?.data?.message || error.message,
                severity: "error",
            }))
        }
    };

    return (
        <Formik
            initialValues={{}}
            onSubmit={(values, action) => onFormSubmit(values, action)}
            validationSchema={PasswordValidation}
            enableReinitialize
        >
            {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                setFieldValue
            }) => (
                <Grid sx={{ display: "flex" }} direction={"column"} width={"100%"} height={"100%"} >
                    <Grid flex={1} overflow={"auto"}>
                        <Grid container spacing={2} pt={2} pb={2} >
                            <Grid item xs={12} md={4}>
                                <Input
                                    type={showPassword ? "text" : "password"}
                                    id='new_password'
                                    name="new_password"
                                    label="New Password *"
                                    onChange={handleChange("new_password")}
                                    value={values?.new_password || ""}
                                    error={Boolean(errors?.new_password)}
                                    helperText={errors?.new_password}
                                    fullWidth
                                    InputProps={{ // <-- This is where the toggle button is added.
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Input
                                    type={showPassword ? "text" : "password"}
                                    id='confirm_password'
                                    name="confirm_password"
                                    label="Confirm Password *"
                                    onChange={handleChange("confirm_password")}
                                    value={values?.confirm_password || ""}
                                    error={Boolean(errors?.confirm_password)}
                                    helperText={errors?.confirm_password}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid flexShrink={0} borderTop={1} borderColor={"rgba(152, 188, 252, 0.16)"} sx={{ padding: "8px 16px" }}>
                        <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <Grid sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <Button size="large" type="submit" loading={false} onClick={() => handleSubmit()}>Change Password</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )
            }
        </Formik >
    )
}