import * as yup from "yup";

export const CandidateValidation = yup.object().shape({
    profile_id: yup.string(),
    sanstha_id: yup.object(),
    name: yup.string().required(),
    gender: yup.string(),
    from_age: yup.string(),
    to_age: yup.string(),
    from_height: yup.string(),
    to_height: yup.string(),
    native_place: yup.string(),
    colour: yup.string(),
    religion: yup.string(),
    mother_tongue: yup.string(),
    communities: yup.string(),
    not_in_father_gotra: yup.string(),
    not_in_mother_gotra: yup.string(),
    manglic: yup.string(),
    matching_horoscope: yup.string(),
    qualification: yup.string(),
    employeed: yup.string(),
    business_company_name: yup.string(),
    annual_income: yup.string(),
    financial_budget: yup.string(),
    marital_status: yup.string(),
    city: yup.string(),
    state: yup.string(),
});