import { get } from "lodash-es";

export const candidateListParser = (response) => {
    try {
        if (response?.result) {
            response = response.result;
        }
        if (!response) {
            return [];
        }

        response.result = response.result.map(function (row, key) {
            return {
                _id: get(row, "_id", ""),
                name: get(row, "name", ""),
                sanstha_id: get(row, "sanstha_id", ""),
                email: get(row, "email", ""),
                mobile: get(row, "mobile", ""),
                gender: get(row, "gender", ""),
                dob: get(row, "dob", ""),
                tob: get(row, "tob", ""),
                birth_place: get(row, "birth_place", ""),
                height: get(row, "height", ""),
                weight: get(row, "weight", ""),
                colour: get(row, "colour", ""),
                native_place: get(row, "native_place", ""),
                religion: get(row, "religion", ""),
                mother_tongue: get(row, "mother_tongue", ""),
                communities: get(row, "communities", ""),
                hobbies: get(row, "hobbies", ""),
                father_name: get(row, "father_name", ""),
                father_gotra: get(row, "father_gotra", ""),
                mother_name: get(row, "mother_name", ""),
                mother_gotra: get(row, "mother_gotra", ""),
                number_of_brother: get(row, "number_of_brother", ""),
                number_of_sister: get(row, "number_of_sister", ""),
                manglig: get(row, "manglig", ""),
                matching_horoscope: get(row, "matching_horoscope", ""),
                qualification: get(row, "qualification", ""),
                business_company_name: get(row, "business_company_name", ""),
                employeed: get(row, "employeed", ""),
                address_of_business: get(row, "address_of_business", ""),
                address_of_residence: get(row, "address_of_residence", ""),
                annual_income: get(row, "annual_income", ""),
                financial_budget: get(row, "financial_budget", ""),
                marital_status: get(row, "marital_status", ""),
                reffered_by: get(row, "reffered_by", ""),
                city: get(row, "city", ""),
                state: get(row, "state", ""),
                zipcode: get(row, "zipcode", ""),
                status: get(row, "status", ""),
                profile: get(row, "profile", ""),
                createdAt: get(row, "createdAt", ""),
                show_to_everyone: get(row, "show_to_everyone", true),
                show_limited: get(row, "show_limited", true),
            }
        });

        return response;

    } catch (error) {
        throw new Error(error);
    }
}