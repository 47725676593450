import React from "react";
import EyeIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { TableCell, TableRow, Typography } from "@mui/material";
import IconButtonIcons from "../../Common/IconButtonIcons";
import TableCommon from "../../Common/Table";
import { format } from "date-fns";
import { handleDateTimeDefault } from "../../../helpers/utils";
import { isAuth } from "../../../helpers/cookies";
import { baseUrl } from "../../../helpers/constants";
import { useDeleteSansthaMutation } from "../../../store/sanstha/sansthaApis";

const SansthaTableComponent = ({ loading, count, data, edit, pagination, handlePagination, fetching = false, convert }) => {
    const [handleDelete] = useDeleteSansthaMutation();
    let columns = [
        {
            title: "Sr. No.",
            sort: false
        },
        {
            title: "Logo",
            field: "logo",
            sort: false
        },
        {
            title: "Name",
            field: "name",
            sort: true
        },
        {
            title: "Email",
            field: "email",
            sort: true
        },
        {
            title: "Mobile",
            field: "mobile",
            sort: true
        },
        {
            title: "Created At",
            field: "createdAt",
            sort: true,
            hide: (isAuth().roles !== "admin")
        },
        {
            title: "Action",
            name: "",
            sort: false
        },
    ];

    const deleteManage = (_id, converted) => {
        if (window.confirm("Are you sure you want to delete?")) {
            if (converted) {
                alert("This member is converted to user, kindly delete the user first and then member.")
                return false;
            }
            handleDelete({ _id });
        }
    }

    const renderTableData = !loading && data && data.length > 0 ? (
        data.map(function (row, index) {
            return (
                <TableRow
                    key={index}
                    sx={{ "td, th": { border: 0, padding: "10px" } }}
                >
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center"><img src={baseUrl + "/" + row.logo} alt="Profile" width={50} /></TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row?.email}</TableCell>
                    <TableCell align="center">{row?.mobile}</TableCell>
                    {isAuth().roles === "admin" ? <TableCell align="center">{handleDateTimeDefault(row?.createdAt)}</TableCell> : null}
                    <TableCell align="center" component="th" scope="row">
                        <IconButtonIcons
                            title="Edit"
                            IconComponent={EditIcon}
                            color="info"
                            onClick={() => edit(row, "Edit")}
                        />
                        <IconButtonIcons
                            title="View"
                            IconComponent={EyeIcon}
                            color="info"
                            onClick={() => edit(row, "View")}
                        />

                        {
                            (isAuth().roles === "admin")
                                ?
                                <IconButtonIcons
                                    title="Delete"
                                    IconComponent={DeleteIcon}
                                    color="info"
                                    onClick={() => deleteManage(row._id, row.converted)}
                                />
                                :
                                null
                        }

                        {
                            !row.converted && (isAuth().roles === "admin")
                                ?
                                <IconButtonIcons
                                    title="Convert to User"
                                    IconComponent={AutorenewIcon}
                                    color="info"
                                    onClick={() => convert(row._id)}
                                />
                                :
                                null
                        }
                    </TableCell>
                </TableRow>
            )
        })
    ) : (
        <TableRow sx={{ "td, th": { border: 0, padding: "10px" } }}>
            <TableCell colSpan={5} align="center">Data not found</TableCell>
        </TableRow>
    );

    return (
        <TableCommon columns={columns} tableData={renderTableData} count={count} loading={loading || fetching} pagination={pagination} handlePagination={handlePagination} />
    )
}

export default SansthaTableComponent;