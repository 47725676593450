import { createSlice } from "@reduxjs/toolkit";

const formField = {
}
const bannerInitialField = {
    sanstha_id: "",
    type: "home_page",
    status: "true",
    url: ""
}

export const QualificationInitialForm = {
    name: "",
    status: "true"
}

export const GotraInitialForm = {
    name: "",
    status: "true"
}

const initialState = {
    showDrawer: false,
    formType: "",
    initialValues: formField,
    bannerInitialValue: bannerInitialField,
    pagination: {
        pageNo: 0,
        limit: 10,
        sortBy: -1,
        sortField: "createdAt",
        keywords: ""
    }
};

export const mastersSlice = createSlice({
    name: "masters",
    initialState,
    reducers: {
        changeMastersInitialState: (state, action) => {
            const { showDrawer, formType, initialValues } = action.payload;

            state.showDrawer = showDrawer;
            if (formType)
                state.formType = formType;
            if (initialValues)
                state.initialValues = initialValues;
            if (!initialValues)
                state.initialValues = formField;
        },
        changeBannerInitialState: (state, action) => {
            const { showDrawer, formType, bannerInitialValue } = action.payload;

            state.showDrawer = showDrawer;
            if (formType)
                state.formType = formType;
            if (bannerInitialValue)
                state.bannerInitialValue = bannerInitialValue;
            if (!bannerInitialValue)
                state.bannerInitialValue = bannerInitialField;
        },
        handlePaginationState: (state, action) => {
            const { payload } = action;
            state.pagination = payload;
        }
    },
});

// Action creators are generated for each case reducer function
export const { changeMastersInitialState, changeBannerInitialState, handlePaginationState } = mastersSlice.actions;

export default mastersSlice;
