import React, { Component } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const htmlToDraftBlocks = (html, moveToEnd) => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    let editorState = EditorState.createWithContent(contentState);
    if (moveToEnd)
        editorState = EditorState.moveFocusToEnd(editorState);
    return editorState;
}

class RichTextEditor extends Component {
    state = {
        editorState: htmlToDraftBlocks(this.props.defaultValue, false),
    }

    componentDidUpdate(prev) {
        if (prev.suggested !== this.props.suggested) {
            this.setState({ editorState: htmlToDraftBlocks(this.props.suggested, true) })
        }
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
        this.props.setValue(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    render() {
        
        const { editorState } = this.state;
        return (
            <Editor
                placeholder={this.props.placeholder}
                editorState={editorState}
                wrapperClassName={`demo-wrapper ${this.props.class || "h-10"}`}
                editorClassName="demo-editor"
                onEditorStateChange={this.onEditorStateChange}
                toolbarClassName="toolbar-class"
                toolbar={{
                    options: ['inline', 'list', 'history'],
                    inline: {
                        inDropdown: false,
                        options: ['bold', 'italic']
                    },
                    list: { inDropdown: false, options: ['unordered'], },
                    history: { inDropdown: false },
                }}
                stripPastedStyles={true}
            />
        );
    }
}

export default RichTextEditor;