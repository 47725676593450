import React from "react";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/material";

const WelcomeImage = () => {
    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
                height: { xs: "10vh", sm: "20vh", md: "100vh", lg: "100vh" },
                width: "auto",
                backgroundColor: { xs: "transparent", md: "primary.main" },
                display: "flex",
                flexDirection: { xs: "row", sm: "row", md: "column", lg: "column" },
                justifyContent: "center",
            }}
        >
            <Box sx={{ width: { xs: "20%", sm: "20%", md: "20%", lg: "20%" } }}>
                <img
                    src="assets/images/logo.png"
                    alt="edubridgeindia-logo"
                    width={"100%"}
                />
            </Box>
        </Stack>
    );
};

export default WelcomeImage;
