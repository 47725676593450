import * as yup from "yup";

export const HomePageValidation = yup.object().shape({
    home_about_title: yup.string().required("Required"),
    home_about_description: yup.string().required("Required"),
    home_about_image_url: yup.string().required("Required"),
});

export const AboutPageValidation = yup.object().shape({
    about_title: yup.string().required("Required"),
    about_description: yup.string().required("Required"),
});

export const ContactPageValidation = yup.object().shape({
    address: yup.string().required("Required"),
    email_1: yup.string(),
    email_2: yup.string(),
    phone_1: yup.string(),
    phone_2: yup.string(),
});