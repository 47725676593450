import React from "react";
import { Formik } from "formik";
import { CandidateValidation } from "./CandidateValidation";
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Tooltip, Typography } from "@mui/material";
import Input from "../../Common/Input";
import Button from "../../Common/Button";
import BasicSelect from "../../Common/Select";
import { useGetGotraListQuery, useGetQualificationListQuery } from "../../../store/masters/mastersApis";
import { communities, ageNumber, maritalStatus, incomeNumber } from "../../../helpers/sampleData";
import SearchIcon from '@mui/icons-material/Search';
import { SelectHeight } from "../../Common/SelectHeight";
import { isAuth } from "../../../helpers/cookies";

export const CandidateSearchComponent = ({ search, initialValues, advance = false }) => {
    const { data: gotraData } = useGetGotraListQuery({ pageNo: 0, limit: 1000, sortBy: -1, sortField: "createdAt", active: true });
    const { data: qualificationData } = useGetQualificationListQuery({ pageNo: 0, limit: 1000, sortBy: -1, sortField: "createdAt", active: true });

    const onFormSubmit = async (values) => {
        search(values);
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => onFormSubmit(values)}
            validationSchema={CandidateValidation}
            enableReinitialize
        >
            {({
                handleChange,
                handleSubmit,
                setFieldValue,
                values,
                errors
            }) => (
                <Grid sx={{ display: "flex" }} direction={"column"} width={"100%"} height={"100%"} >
                    {console.log(errors, "errors")}
                    <Grid flex={1} px={2} py={2} overflow={"auto"}>
                        <Grid container spacing={2} >
                            <Grid item xs={12} md={6} hidden={!advance}>
                                <Input
                                    id='name'
                                    name="name"
                                    label="Name *"
                                    onChange={handleChange("name")}
                                    value={values?.name || ""}
                                    error={Boolean(errors.name)}
                                    helperText={errors.name}
                                    fullWidth

                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <FormLabel id="gender-radio-button" sx={{ pr: 2 }}>Gender</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="gender-radio-button"
                                        name="gender"
                                        row
                                        onChange={handleChange("gender")}
                                        value={values?.gender || "all"}
                                    >
                                        <FormControlLabel value="female" color="inheri" control={<Radio />} label="Female" />
                                        <FormControlLabel value="male" color="inherit" control={<Radio />} label="Male" />
                                        <FormControlLabel value="all" color="inherit" control={<Radio />} label="All" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} pb={2} >
                            <Grid item xs={12} md={6}>
                                <Typography size={"small"}>From Age</Typography>
                                <BasicSelect
                                    size="small"
                                    value={values?.from_age || ""}
                                    onChange={handleChange("from_age")}
                                    displayEmpty
                                    label="From Age"
                                    name="from_age"
                                    id="from_age"
                                    items={ageNumber()}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography size={"small"}>To Age</Typography>
                                <BasicSelect
                                    size="small"
                                    value={values?.to_age || ""}
                                    onChange={handleChange("to_age")}
                                    displayEmpty
                                    label="To Age"
                                    name="to_age"
                                    id="to_age"
                                    items={ageNumber()}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} pb={2}>
                            <Grid item xs={12} md={6}>
                                <Typography size={"small"}>From Height</Typography>
                                <SelectHeight
                                    id='from_height'
                                    name="from_height"
                                    onChange={(val) => console.log("from_height", val)}
                                    // value={values?.from_height || ""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography size={"small"}>To Height</Typography>
                                <SelectHeight
                                    id='to_height'
                                    name="to_height"
                                    label="To Height"
                                    onChange={(val) => console.log("to_height", val)}
                                    // value={values?.to_height || ""}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} pb={2}>
                            <Grid item xs={12} md={6}>
                                <BasicSelect
                                    size="small"
                                    value={values?.marital_status || "N/A"}
                                    onChange={handleChange("marital_status")}
                                    displayEmpty
                                    label="Marital Status"
                                    name="marital_status"
                                    id="marital_status"
                                    items={maritalStatus}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} hidden={!advance}>
                                <Input
                                    id='native_place'
                                    name="native_place"
                                    label="Native Place"
                                    onChange={handleChange("native_place")}
                                    value={values?.native_place || ""}
                                    error={Boolean(errors.native_place)}
                                    helperText={errors.native_place}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} pb={2}>
                            <Grid item xs={12} md={6} hidden={!advance}>
                                <Input
                                    id='colour'
                                    name="colour"
                                    label="Complexion Colour"
                                    onChange={handleChange("colour")}
                                    value={values?.colour || ""}
                                    error={Boolean(errors.colour)}
                                    helperText={errors.colour}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6} hidden={!advance}>
                                <BasicSelect
                                    size="small"
                                    value={values?.religion || ""}
                                    onChange={handleChange("religion")}
                                    displayEmpty
                                    label="Religion"
                                    name="religion"
                                    id="religion"
                                    items={[
                                        { label: "Choose", value: 0 },
                                        { label: "Hindu", value: "Hindu" },
                                        { label: "Jain", value: "Jain" },
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} hidden={!advance}>
                                <BasicSelect
                                    size="small"
                                    value={values?.mother_tongue || ""}
                                    onChange={handleChange("mother_tongue")}
                                    displayEmpty
                                    label="Mother Tongue"
                                    name="mother_tongue"
                                    id="mother_tongue"
                                    items={[
                                        { label: "Choose", value: 0 },
                                        { label: "Marwadi", value: "Marwadi" },
                                    ]}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} pb={2} >
                            <Grid item xs={12} md={6}>
                                <BasicSelect
                                    size="small"
                                    value={values?.communities || ""}
                                    onChange={handleChange("communities")}
                                    displayEmpty
                                    label="Communities"
                                    name="communities"
                                    id="communities"
                                    items={communities}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <BasicSelect
                                    size="small"
                                    value={values?.not_in_father_gotra || ""}
                                    onChange={handleChange("not_in_father_gotra")}
                                    displayEmpty
                                    label="Not in Father Gotra"
                                    name="not_in_father_gotra"
                                    id="not_in_father_gotra"
                                    items={gotraData || []}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <BasicSelect
                                    size="small"
                                    value={values?.not_in_mother_gotra || ""}
                                    onChange={handleChange("not_in_mother_gotra")}
                                    displayEmpty
                                    label="Not in Mother Gotra"
                                    name="not_in_mother_gotra"
                                    id="not_in_mother_gotra"
                                    items={gotraData || []}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} pb={2} >
                            <Grid item xs={12} md={6}>
                                <FormControl>
                                    <FormLabel id="manglic-radio-button" sx={{ pr: 2 }}>Manglic or Non Manglic</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="manglic-radio-button"
                                        name="manglic"
                                        row
                                        onChange={handleChange("manglic")}
                                        value={values?.manglic || "n/a"}
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Manglic" />
                                        <FormControlLabel value="false" control={<Radio />} label="Non Manglic" />
                                        <FormControlLabel value="n/a" control={<Radio />} label="Does not matter" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6} hidden={!advance}>
                                <FormControl>
                                    <FormLabel id="horoscope-radio-button" sx={{ pr: 2 }}>Matching Horoscope</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="horoscope-radio-button"
                                        name="matching_horoscope"
                                        row
                                        onChange={handleChange("matching_horoscope")}
                                        value={values?.matching_horoscope || "n/a"}
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="false" control={<Radio />} label="No" />
                                        <FormControlLabel value="n/a" control={<Radio />} label="Does not matter" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} pb={2} >
                            <Grid item xs={12} md={6}>
                                <BasicSelect
                                    size="small"
                                    value={values?.qualification || ""}
                                    onChange={handleChange("qualification")}
                                    displayEmpty
                                    label="Qualification"
                                    name="qualification"
                                    id="qualification"
                                    items={qualificationData || []}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <BasicSelect
                                    size="small"
                                    value={values?.employeed || ""}
                                    onChange={handleChange("employeed")}
                                    displayEmpty
                                    label="Business"
                                    name="employeed"
                                    id="employeed"
                                    items={[
                                        { label: "Choose", value: 0 },
                                        { label: "Own Business", value: "Own Business" },
                                        { label: "Govt Job", value: "Govt Job" },
                                        { label: "Private Company", value: "Private Company" },
                                        { label: "Public", value: "Public" },
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Input
                                    id='business_company_name'
                                    name="business_company_name"
                                    label="Business or Service Name"
                                    onChange={handleChange("business_company_name")}
                                    value={values?.business_company_name || ""}
                                    error={Boolean(errors.business_company_name)}
                                    helperText={errors.business_company_name}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <BasicSelect
                                    size="small"
                                    value={values?.annual_income || ""}
                                    onChange={handleChange("annual_income")}
                                    displayEmpty
                                    label="Annual Income"
                                    name="annual_income"
                                    id="annual_income"
                                    items={incomeNumber()}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} hidden={!advance}>
                                <Input
                                    id='financial_budget'
                                    name="financial_budget"
                                    label="Financial Budget"
                                    onChange={handleChange("financial_budget")}
                                    value={values?.financial_budget || ""}
                                    error={Boolean(errors.financial_budget)}
                                    helperText={errors.financial_budget}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Input
                                    id='city'
                                    name="city"
                                    label="City"
                                    onChange={handleChange("city")}
                                    value={values?.city || ""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Input
                                    id='state'
                                    name="state"
                                    label="State"
                                    onChange={handleChange("state")}
                                    value={values?.state || ""}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid flexShrink={0} borderTop={1} borderColor={"rgba(152, 188, 252, 0.16)"} sx={{ padding: "8px 16px" }}>
                        <Grid sx={{ display: "flex", justifyContent: "center" }}>
                            <Grid sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                {
                                    isAuth().roles === "admin"
                                        ?
                                        <Button startIcon={<SearchIcon />} size="large" type="submit" loading={false} onClick={() => handleSubmit()}>Search</Button>
                                        :
                                        <Tooltip title="Contact admin for this feature" style={{ fontSize: "20px" }}>
                                            <Button startIcon={<SearchIcon />} size="large" type="submit" loading={false}>Search</Button>
                                        </Tooltip>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )
            }
        </Formik >
    )
}