import { get } from "lodash-es";

export const userListParser = (response, dispatch) => {
    try {
        if (response?.result) {
            response = response.result;
        }
        if (!response) {
            return [];
        }

        response.result = response.result.map(function (row, key) {
            return {
                _id: get(row, "_id", ""),
                name: get(row, "name", ""),
                email: get(row, "email", ""),
                status: get(row, "status", ""),
                roles: get(row, "roles", ""),
                mobile: get(row, "mobile", ""),
                member_id: get(row, "member_id", ""),
                sanstha_id: get(row, "sanstha_id", ""),
                createdAt: get(row, "createdAt", "")
            }
        });

        return response;

    } catch (error) {
        throw new Error(error);
    }
}

export const UserParser = (response) => {
    try {
        if (response?.result) {
            response = response.result;
        }
        if (!response) {
            return [];
        }

        return response;

    } catch (error) {
        throw new Error(error);
    }
}