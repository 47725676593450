import React, { useEffect } from "react";
import { Button, Grid, Paper, Stack, Typography } from "@mui/material";
import SansthaTableComponent from "../../../components/admin/sanstha-manager/SansthaTable";
import { SansthaAddDrawer } from "../../../components/admin/sanstha-manager/SansthaAddDrawer";
import { useGetSansthaListQuery } from "../../../store/sanstha/sansthaApis";
import { SearchRecords } from "../../../components/Common/SearchRecords";
import { useConvertToUserMutation } from "../../../store/user/userApis";
import { useDispatch } from "react-redux";
import { setSnackBar } from "../../../store/common/commonSlice";
import { Add } from "@mui/icons-material";

const SansthaManagerContainer = (props) => {
    const dispatch = useDispatch();
    const { showDrawer, formType, initialValues, pagination } = props;
    const { isLoading, data: sanstha, isFetching, refetch } = useGetSansthaListQuery(pagination);
    const [convertToUser, { isLoading: convertUserLoading }] = useConvertToUserMutation();

    const handleAddSanstha = () => {
        props.changeSansthaInitialState({
            showDrawer: true,
            formType: "Add"
        })
    }

    useEffect(() => {
        props.handlePaginationState({
            pageNo: 0,
            limit: 10,
            sortBy: -1,
            sortField: "createdAt",
            keywords: ""
        })
    }, []);

    const handlePagination = (setting) => {
        props.handlePaginationState(setting);
    }

    const handleDrawerClose = () => {
        props.changeSansthaInitialState({ showDrawer: false, formType: "", initialValues: {} })
    }

    const handleSansthaEdit = (data, type) => {
        let payload = { ...data };
        delete payload.createdAt;
        props.changeSansthaInitialState({ showDrawer: true, formType: type, initialValues: payload })
    }

    const handleMemberToUserConvert = async (id) => {
        try {
            let payload = {
                type: "sanstha",
                _id: id
            }
            await convertToUser(payload).unwrap();
            refetch();
            dispatch(setSnackBar({
                open: true,
                message: "User converted successfully",
                severity: "success",
            }))
        } catch (error) {
            dispatch(setSnackBar({
                open: true,
                message: error?.data?.message || error.message,
                severity: "error",
            }))
        }
    }


    return (
        <Stack spacing={1}>
            <Paper sx={{ marginBottom: "24px", padding: 1.5 }}>
                <Grid container justifyContent="space-between">
                    <Grid item sx={{ alignSelf: "center" }}>
                        <Typography variant="h6">List of Sanstha</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            disableElevation
                            variant="contained"
                            sx={{ borderRadius: "50px" }}
                            onClick={() => handleAddSanstha()}
                            startIcon={<Add />}
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>
            </Paper>

            <Grid container>
                <SearchRecords handlePagination={handlePagination} pagination={pagination} />
                <Grid item xs={12}>
                    <Paper
                        sx={{ p: 2, color: "#071B2A", fontWeight: "400" }}
                        elevation={0}
                    >
                        <SansthaTableComponent fetching={isFetching} edit={(val, type) => handleSansthaEdit(val, type)} convert={(id) => handleMemberToUserConvert(id)} loading={isLoading} count={sanstha?.count || 0} data={sanstha?.result || []} pagination={pagination} handlePagination={(val) => handlePagination(val)} />
                    </Paper>
                </Grid>
            </Grid>

            {/* add taf drawer */}
            <SansthaAddDrawer show={showDrawer} close={handleDrawerClose} formType={formType} initialValues={initialValues} />

        </Stack >
    );
}

export default SansthaManagerContainer;