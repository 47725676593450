import { createSlice } from "@reduxjs/toolkit";

const formInitialValue = {
    name: "",
    email: "",
    mobile: "",
    gender: "male",
    dob: "",
    tob: "",
    birth_place: "",
    height: "",
    weight: "",
    colour: "",
    native_place: "",
    religion: "",
    mother_tongue: "",
    communities: "",
    hobbies: "",
    father_name: "",
    father_gotra: "",
    mother_name: "",
    mother_gotra: "",
    number_of_brother: "",
    number_of_sister: "",
    manglic: "no",
    matching_horoscope: "no",
    qualification: "",
    employeed: "",
    business_company_name: "",
    address_of_business: "",
    address_of_residence: "",
    annual_income: "",
    financial_budget: "",
    marital_status: "",
    reffered_by: "",
    city: "",
    state: "",
    zipcode: "",
    status: true
}

const initialState = {
    showDrawer: false,
    formType: "",
    initialValues: { ...formInitialValue },
    advanceSearchInitialVal: {
        profile_id: "",
        name: "",
        gender: "all",
        from_age: "",
        to_age: "",
        from_height: "0",
        to_height: "0",
        native_place: "",
        colour: "",
        religion: "",
        mother_tongue: "",
        communities: "",
        not_in_father_gotra: "",
        not_in_mother_gotra: "",
        manglic: "n/a",
        matching_horoscope: "n/a",
        qualification: "",
        employeed: "",
        business_company_name: "",
        annual_income: "",
        financial_budget: "",
        marital_status: "N/A",
        city: "",
        state: "",
        pageNo: 0,
        limit: 12,
        sortBy: -1,
        sortField: "createdAt",
        keywords: ""
    },
    candidateSearchInitialVal: {
        profile_id: "",
        name: "",
        gender: "all",
        from_age: "",
        to_age: "",
        from_height: "0",
        to_height: "0",
        native_place: "",
        colour: "",
        religion: "",
        mother_tongue: "",
        communities: "",
        not_in_father_gotra: "",
        not_in_mother_gotra: "",
        manglic: "n/a",
        matching_horoscope: "n/a",
        qualification: "",
        employeed: "",
        business_company_name: "",
        annual_income: "",
        financial_budget: "",
        marital_status: "N/A",
        city: "",
        state: "",
        pageNo: 0,
        limit: 12,
        sortBy: -1,
        sortField: "createdAt",
        keywords: ""
    },
    normalPagination: {
        pageNo: 0,
        limit: 10,
        sortBy: -1,
        sortField: "createdAt",
        keywords: ""
    }
};

export const candidateSlice = createSlice({
    name: "candidate",
    initialState,
    reducers: {
        changeCandidateInitialState: (state, action) => {
            const { showDrawer, formType, initialValues } = action.payload;

            state.showDrawer = showDrawer;
            if (formType)
                state.formType = formType;
            if (initialValues)
                state.initialValues = initialValues;
            if (!initialValues)
                state.initialValues = formInitialValue;
        },
        changeAdvanceSearchInitialState: (state, action) => {
            const { initialValues } = action.payload;

            if (initialValues)
                state.advanceSearchInitialVal = initialValues;
        },
        changeCandidateSearchInitialState: (state, action) => {
            const { initialValues } = action.payload;

            if (initialValues)
                state.candidateSearchInitialVal = initialValues;
        },
        handleNormalPaginationState: (state, action) => {
            const { payload } = action;
            state.normalPagination = payload;
        }
    },
});

// Action creators are generated for each case reducer function
export const { changeCandidateInitialState, handleNormalPaginationState, changeAdvanceSearchInitialState, changeCandidateSearchInitialState } = candidateSlice.actions;

export default candidateSlice;
