export const workStatus = [
    { value: "Student", label: "Student" },
    { value: "Job", label: "Job" },
    { value: "Business", label: "Business" },
    { value: "Professional", label: "Professional" },
    { value: "Retailer", label: "Retailer" },
    { value: "Industrial", label: "Industrial" },
    { value: "Housewife", label: "Housewife" },
    { value: "Retried", label: "Retried" },
    { value: "CA", label: "CA" },
    { value: "MBA", label: "MBA" },
    { value: "Other", label: "Other" },
]

export const relation = ["Wife", "Husband", "Son", "Daughter", "Son in law", "Daughter in law", "Brother", "Sister", "Mother", "Father", "Other"]

export const memberStatus = [
    { value: "Patron", label: "Patron" },
    { value: "Advisor", label: "Advisor" },
    { value: "President", label: "President" },
    { value: "Working President", label: "Working President" },
    { value: "Vice President", label: "Vice President" },
    { value: "Secratary", label: "Secratary" },
    { value: "Joint Secratary", label: "Joint Secratary" },
    { value: "Treasurer", label: "Treasurer" },
    { value: "Organisation Secretary", label: "Organisation Secretary" },
    { value: "Committee Member", label: "Committee Member" },
    { value: "Member", label: "Member" }
]

export const communities = [
    { value: "Agarwal", label: "Agarwal" },
    { value: "Maheshwari", label: "Maheshwari" },
    { value: "Ostwal", label: "Ostwal" },
    { value: "Digamber", label: "Digamber" },
    { value: "Swetamber", label: "Swetamber" },
]

export const maritalStatus = [
    { value: "N/A", label: "N/A" },
    { value: "Unmarried", label: "Unmarried" },
    { value: "Divorcee", label: "Divorcee" },
    { value: "Widow", label: "Widow" },
]

export const ageNumber = () => {
    let data = [{ label: "Choose", value: 0 }];
    for (let index = 18; index < 100; index++) {
        data.push({ label: index, value: index.toString() });
    }
    return data;
}

export const heightNumber = () => {
    let data = [{ label: "Choose", value: 0 }];
    for (let index = 3; index < 10; index++) {
        data.push({ label: index + " feet", value: index.toString() });
        data.push({ label: (index) + " feet 5 inch", value: (index + 0.5).toString() });
    }
    return data;
}

export const incomeNumber = () => {
    let data = [{ label: "Choose", value: 0 }];
    for (let index = 1; index < 25; index++) {
        data.push({ label: `${index} Lac - ${index + 1} Lac`, value: index + "00000-" + parseInt(index + 1) + "00000" });
    }
    return data;
}