import React from "react";
import { Formik } from "formik";
import { HomePageValidation } from "./validation";
import { FormControl, FormHelperText, Grid, Paper, Typography } from "@mui/material";
import Button from "../../Common/Button";
import { useDispatch } from "react-redux";
import { setSnackBar } from "../../../store/common/commonSlice";
import Input from "../../Common/Input";
import { CommonFileUploadToServer } from "../../Common/CommonFileUploadToServer";
import RichTextEditor from "../../Common/editor/RichTextEditor";
import { useUpdateHomePageCMSMutation } from "../../../store/cms/cmsApis";

export const HomePageCmsForm = ({ homeInitialValues, sansthaId }) => {
    const dispatch = useDispatch();
    const [updateHomePage] = useUpdateHomePageCMSMutation();

    const onFormSubmit = async (values) => {
        try {
            let payload = { ...values };
            delete payload.createdAt;
            delete payload.updatedAt;
            payload.sanstha_id = sansthaId;
            await updateHomePage(payload).unwrap();
            dispatch(setSnackBar({
                open: true,
                message: "Data updated successfully",
                severity: "success",
            }))
        } catch (error) {
            dispatch(setSnackBar({
                open: true,
                message: error?.data?.message || error.message,
                severity: "error",
            }))
        }
    };

    console.log(homeInitialValues, "homeInitialValues");

    return (
        <Formik
            initialValues={homeInitialValues}
            onSubmit={(values) => onFormSubmit(values)}
            validationSchema={HomePageValidation}
            enableReinitialize
        >
            {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                setFieldValue
            }) => (
                <Paper
                    sx={{ p: 2, color: "#071B2A", fontWeight: "400" }}
                    elevation={0}
                >
                    <Grid sx={{ display: "flex" }} direction={"column"} width={"100%"} height={"100%"} >
                        <Grid flex={1} px={2} py={5} overflow={"auto"}>
                            <Grid container spacing={2} >
                                <Grid item xs={12} md={12}>
                                    <Typography variant="h6">Home About Us CMS</Typography>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Input
                                        id='home_about_title'
                                        name="home_about_title"
                                        label="About Title"
                                        onChange={handleChange("home_about_title")}
                                        value={values?.home_about_title || ""}
                                        error={Boolean(errors.home_about_title)}
                                        helperText={errors.home_about_title}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <CommonFileUploadToServer
                                        name="home_about_image_url"
                                        onChange={(val) => setFieldValue("home_about_image_url", val)}
                                        value={values?.home_about_image_url || ""}
                                        label="About Image"
                                        error={Boolean(errors.home_about_image_url)}
                                        helperText={errors.home_about_image_url}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <FormControl fullWidth error={Boolean(errors.home_about_description)}>
                                        <RichTextEditor
                                            placeholder="Home about description"
                                            class="h-20"
                                            setValue={(d) => setFieldValue("home_about_description", d)}
                                            defaultValue={values?.home_about_description || ""}
                                        />
                                        {Boolean(errors.home_about_description) ? (
                                            <FormHelperText>{errors.home_about_description}</FormHelperText>
                                        ) : null}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography variant="h6">Home Why Us CMS</Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='home_why_title'
                                        name="home_why_title"
                                        label="Home Why Us Title"
                                        onChange={handleChange("home_why_title")}
                                        value={values?.home_why_title || ""}
                                        error={Boolean(errors.home_why_title)}
                                        helperText={errors.home_why_title}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='home_why_description'
                                        name="home_why_description"
                                        label="Home Why Us Description"
                                        onChange={handleChange("home_why_description")}
                                        value={values?.home_why_description || ""}
                                        error={Boolean(errors.home_why_description)}
                                        helperText={errors.home_why_description}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Input
                                        id='home_total_tax_percent'
                                        name="home_total_tax_percent"
                                        label="कुल इन्कम टैक्स में हिस्सा"
                                        onChange={handleChange("home_total_tax_percent")}
                                        value={values?.home_total_tax_percent || ""}
                                        error={Boolean(errors.home_total_tax_percent)}
                                        helperText={errors.home_total_tax_percent}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Input
                                        id='home_total_daan_percent'
                                        name="home_total_daan_percent"
                                        label="कुल दान Percent"
                                        onChange={handleChange("home_total_daan_percent")}
                                        value={values?.home_total_daan_percent || ""}
                                        error={Boolean(errors.home_total_daan_percent)}
                                        helperText={errors.home_total_daan_percent}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Input
                                        id='home_total_mandir'
                                        name="home_total_mandir"
                                        label="भारत में कुल मंदिर"
                                        onChange={handleChange("home_total_mandir")}
                                        value={values?.home_total_mandir || ""}
                                        error={Boolean(errors.home_total_mandir)}
                                        helperText={errors.home_total_mandir}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Input
                                        id='home_total_yogdan_percent'
                                        name="home_total_yogdan_percent"
                                        label="भारत के विकास में योगदान Percent"
                                        onChange={handleChange("home_total_yogdan_percent")}
                                        value={values?.home_total_yogdan_percent || ""}
                                        error={Boolean(errors.home_total_yogdan_percent)}
                                        helperText={errors.home_total_yogdan_percent}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <FormControl fullWidth error={Boolean(errors.home_why_detail_description)}>
                                        <RichTextEditor
                                            placeholder="Home why detail description"
                                            class="h-20"
                                            setValue={(d) => setFieldValue("home_why_detail_description", d)}
                                            defaultValue={values?.home_why_detail_description || ""}
                                        />
                                        {Boolean(errors.home_why_detail_description) ? (
                                            <FormHelperText>{errors.home_why_detail_description}</FormHelperText>
                                        ) : null}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography variant="h6">Home Video</Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='home_video_title'
                                        name="home_video_title"
                                        label="Home Video Title"
                                        onChange={handleChange("home_video_title")}
                                        value={values?.home_video_title || ""}
                                        error={Boolean(errors.home_video_title)}
                                        helperText={errors.home_video_title}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='home_video_description'
                                        name="home_video_description"
                                        label="Home Video Description"
                                        onChange={handleChange("home_video_description")}
                                        value={values?.home_video_description || ""}
                                        error={Boolean(errors.home_video_description)}
                                        helperText={errors.home_video_description}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='home_video_url'
                                        name="home_video_url"
                                        label="Home Video Youtube URL"
                                        onChange={handleChange("home_video_url")}
                                        value={values?.home_video_url || ""}
                                        error={Boolean(errors.home_video_url)}
                                        helperText={errors.home_video_url}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <CommonFileUploadToServer
                                        name="home_video_thumbnail_url"
                                        onChange={(val) => setFieldValue("home_video_thumbnail_url", val)}
                                        value={values?.home_video_thumbnail_url || ""}
                                        label="Home Video Thumbnail"
                                        error={Boolean(errors.home_video_thumbnail_url)}
                                        helperText={errors.home_video_thumbnail_url}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid flexShrink={0} borderTop={1} borderColor={"rgba(152, 188, 252, 0.16)"} sx={{ padding: "8px 16px" }}>
                            <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                                <Grid sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    <Button size="large" type="submit" loading={false} onClick={() => handleSubmit()}>Save</Button>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Paper>
            )
            }
        </Formik >
    )
}