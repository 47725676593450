import { get } from "lodash-es";

export const membersListParser = (response, dispatch) => {
    try {
        if (response?.result) {
            response = response.result;
        }
        if (!response) {
            return [];
        }

        if (!response.result) {
            return [];
        }

        response.result = response.result.map(function (row, key) {
            return {
                _id: get(row, "_id", ""),
                sanstha_id: get(row, "sanstha_id", ""),
                name: get(row, "name", ""),
                email: get(row, "email", ""),
                mobile: get(row, "mobile", ""),
                alternate_no: get(row, "alternate_no", ""),
                gender: get(row, "gender", ""),
                gotra: get(row, "gotra", ""),
                spouse_name: get(row, "spouse_name", ""),
                work_status: get(row, "work_status", ""),
                member_status: get(row, "member_status", ""),
                city: get(row, "city", ""),
                state: get(row, "state", ""),
                zipcode: get(row, "zipcode", ""),
                status: get(row, "status", ""),
                converted: get(row, "converted", ""),
                profile: get(row, "profile", ""),
                dob: get(row, "dob", ""),
                business_name: get(row, "business_name", ""),
                address: get(row, "address", ""),
                bio: get(row, "bio", ""),
                qualification: get(row, "qualification", ""),
                native_place: get(row, "native_place", ""),
                marriage_date: get(row, "marriage_date", ""),
                family_details: get(row, "family_details", []),
                createdAt: get(row, "createdAt", "")
            }
        }) || [];

        console.log(response.result, "response");

        return response;

    } catch (error) {
        throw new Error(error);
    }
}

export const membersParser = (response) => {
    try {
        if (response?.result) {
            response = response.result;
        }
        if (!response) {
            return [];
        }

        return response;

    } catch (error) {
        throw new Error(error);
    }
}