import React, { useEffect } from "react";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import { ContactPageCmsForm } from "../../../components/admin/cms/contact-page";
import AutoCompleteServerSide from "../../../components/Common/AutoCompleteServerSide";
import { useGetContactPageCmsQuery } from "../../../store/cms/cmsApis";
import { LimitScreenLoadingGrow } from "../../../components/Common/FullScreenLoadingGrow";
import { contactInitialForm } from "../../../store/cms/cmsSlice";

const ContactPageCmsManagerContainer = (props) => {
    const { contactInitialValues, sansthaId } = props;
    const [manualLoading, setManualLoading] = React.useState(false);
    const { data, isLoading, isSuccess, isFetching } = useGetContactPageCmsQuery({ sanstha_id: sansthaId?._id }, { skip: !sansthaId });

    useEffect(() => {
        if (isSuccess && !isFetching) {
            setManualLoading(false);
        }
    }, [isSuccess, isFetching])

    useEffect(() => {
        if (data) {
            props.changeContactInitialState(data);
            if (isSuccess && !isFetching) {
                setManualLoading(false);
            }
        }
    }, [data])


    const fetchCmsData = (val) => {
        setManualLoading(true);
        props.changeContactInitialState(contactInitialForm);
        props.changeSansthaId(val);
    }

    return (
        <Stack spacing={1}>
            <Paper sx={{ marginBottom: "24px", padding: 1.5 }}>
                <Grid container justifyContent="space-between">
                    <Grid item sx={{ alignSelf: "center" }}>
                        <Typography variant="h6">Contact Page CMS</Typography>
                    </Grid>
                    <Grid item sx={{ minWidth: "400px" }}>
                        <AutoCompleteServerSide
                            label="Type & Select Sanstha *"
                            name="sanstha_id"
                            id="sanstha_id"
                            fullWidth
                            defaultValue={sansthaId}
                            fetchDataFunction={(d) => props.getSansthaList(d)}
                            onChange={(val) => fetchCmsData(val)}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <Grid container>
                <Grid item xs={12}>

                    {
                        isLoading || isFetching || manualLoading
                            ?
                            <Paper
                                sx={{ p: 2, color: "#071B2A", fontWeight: "400" }}
                                elevation={0}
                            >
                                <LimitScreenLoadingGrow />
                            </Paper>
                            :
                            contactInitialValues && sansthaId
                                ?
                                <ContactPageCmsForm contactInitialValues={contactInitialValues} sansthaId={sansthaId?._id} />
                                :
                                <Paper
                                    sx={{ p: 2, color: "#071B2A", fontWeight: "400" }}
                                    elevation={0}
                                >
                                    <Grid sx={{ display: "flex" }} direction={"column"} width={"100%"} height={"100%"} >
                                        <Typography variant="h6">Please Select Sanstha Id</Typography>
                                    </Grid>
                                </Paper>
                    }

                </Grid>
            </Grid>
        </Stack >
    );
}

export default ContactPageCmsManagerContainer;