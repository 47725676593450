import { createSlice } from "@reduxjs/toolkit";

export const memberDefaultFormValue = {
    sanstha_id: "",
    name: "",
    email: "",
    mobile: "",
    gender: "male",
    gotra: "",
    spouse_name: "",
    work_status: "",
    member_status: "",
    city: "",
    state: "",
    zipcode: "",
    bio: "",
    status: true
}

const initialState = {
    showDrawer: false,
    formType: "",
    initialValues: { ...memberDefaultFormValue },
    pagination: {
        pageNo: 0,
        limit: 10,
        sortBy: -1,
        sortField: "createdAt",
        keywords: ""
    }
};

export const membersSlice = createSlice({
    name: "members",
    initialState,
    reducers: {
        changeMembersInitialState: (state, action) => {
            const { showDrawer, formType, initialValues } = action.payload;

            state.showDrawer = showDrawer;
            if (formType)
                state.formType = formType;
            if (initialValues)
                state.initialValues = initialValues;
            if (!initialValues)
                state.initialValues = memberDefaultFormValue;
        },
        handlePaginationState: (state, action) => {
            const { payload } = action;
            state.pagination = payload;
        }
    },
});

// Action creators are generated for each case reducer function
export const { changeMembersInitialState, handlePaginationState } = membersSlice.actions;

export default membersSlice;
