import { get } from "lodash-es";

export const sansthaListParser = (response, dispatch) => {
    try {
        if (response?.result) {
            response = response.result;
        }
        if (!response) {
            return [];
        }

        response.result = response.result.map(function (row, key) {
            return {
                _id: get(row, "_id", ""),
                name: get(row, "name", ""),
                email: get(row, "email", ""),
                status: get(row, "status", ""),
                mobile: get(row, "mobile", ""),
                converted: get(row, "converted", ""),
                createdAt: get(row, "createdAt", ""),
                logo: get(row, "logo", ""),
                description: get(row, "description", "")
            }
        });

        return response;

    } catch (error) {
        throw new Error(error);
    }
}


export const sansthaDropdownListParser = (response, dispatch) => {
    try {
        if (response?.result) {
            response = response.result;
        }
        if (!response) {
            return [];
        }

        return response.result.map(function (row, key) {
            return {
                _id: get(row, "_id", ""),
                name: get(row, "name", ""),
            }
        });

    } catch (error) {
        throw new Error(error);
    }
}

export const sansthaParser = (response) => {
    try {
        if (response?.result) {
            response = response.result;
        }
        if (!response) {
            return [];
        }

        return response;

    } catch (error) {
        throw new Error(error);
    }
}