import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from "../../helpers/constants";
import { getCookie } from "../../helpers/cookies";
import { sansthaListParser, sansthaDropdownListParser } from './sansthaParser';

const sansthaApis = createApi({
    reducerPath: 'sansthaApis',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getCookie("token");
            if (token) {
                headers.set('Authorization', `BEARER ${token}`)
            }
            return headers;
        }
    }),
    keepUnusedDataFor: 0,
    tagTypes: ["Sanstha"],
    endpoints: build => ({
        getSansthaList: build.query({
            query: (params) => ({
                url: "/sanstha/list",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => sansthaListParser(response),
            providesTags: ['Sanstha'],
            keepUnusedDataFor: 5,
        }),
        getDropdownSansthaList: build.query({
            query: (params) => ({
                url: "/sanstha/list",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => sansthaDropdownListParser(response),
            providesTags: ['Sanstha'],
            keepUnusedDataFor: 5,
        }),
        addNewSanstha: build.mutation({
            query: (payload) => ({
                url: "/sanstha",
                method: "POST",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Sanstha'],
        }),
        updateSanstha: build.mutation({
            query: (payload) => ({
                url: "/sanstha",
                method: "PUT",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Sanstha'],
        }),
        deleteSanstha: build.mutation({
            query: (payload) => ({
                url: "/sanstha",
                method: "DELETE",
                params: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Sanstha'],
        }),
    }),
});

export const { useGetSansthaListQuery, useGetDropdownSansthaListQuery, useAddNewSansthaMutation, useUpdateSansthaMutation, useDeleteSansthaMutation } = sansthaApis;
export default sansthaApis;