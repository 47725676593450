import * as yup from "yup";

export const ProfileValidation = yup.object().shape({
    name: yup.string().required("Required"),
    email: yup.string().required("Required"),
    mobile: yup.string().required("Required"),
});

export const PasswordValidation = yup.object().shape({
    new_password: yup
        .string()
        .required("Required"),
    confirm_password: yup
        .string()
        .oneOf([yup.ref('new_password'), null], "Passwords must match")
        .required("Required"),
});