import React from "react";
import EyeIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { FormControlLabel, Switch, TableCell, TableRow } from "@mui/material";
import IconButtonIcons from "../../Common/IconButtonIcons";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import TableCommon from "../../Common/Table";
import { setSnackBar } from "../../../store/common/commonSlice";
import { useDeleteGotraMutation, useUpdateGotraMutation } from "../../../store/masters/mastersApis";
import { useDispatch } from "react-redux";
import { isAuth } from "../../../helpers/cookies";
import { handleDateTimeDefault } from "../../../helpers/utils";

const GotraTableComponent = ({ loading, count, data, edit, pagination, handlePagination, fetching = false }) => {
    const dispatch = useDispatch();
    const [updateGotra] = useUpdateGotraMutation();
    const [handleDelete] = useDeleteGotraMutation();

    let columns = [
        {
            title: "Sr. No.",
            sort: false
        },
        {
            title: "Name",
            field: "name",
            sort: true
        },
        {
            title: "Status",
            field: "status",
            sort: true
        },
        {
            title: "Created At",
            field: "createdAt",
            sort: true
        },
        {
            title: "Action",
            name: "",
            sort: false
        },
    ];

    const handleStatus = async (status, row) => {
        try {
            let payload = {
                _id: row._id,
                name: row.name,
                status: status
            }
            await updateGotra(payload).unwrap();
            dispatch(setSnackBar({
                open: true,
                message: "Status updated successfully",
                severity: "success",
            }))
        } catch (error) {
            dispatch(setSnackBar({
                open: true,
                message: error?.data?.message || error.message,
                severity: "error",
            }))
        }
    }

    const deleteManage = async (_id) => {
        try {
            if (window.confirm("Are you sure you want to delete?")) {
                const response = await handleDelete({ _id });
                if (response?.error)
                    throw new Error(response?.error?.message);

                dispatch(setSnackBar({
                    open: true,
                    message: "Deleted successfully",
                    severity: "success",
                }))
            }
        } catch (error) {
            dispatch(setSnackBar({
                open: true,
                message: error?.data?.message || error.message,
                severity: "error",
            }))
        }

    }

    const renderTableData = !loading && data && data.length > 0 ? (
        data.map(function (row, index) {
            return (
                <TableRow
                    key={index}
                    sx={{ "td, th": { border: 0, padding: "10px" } }}
                >
                    <TableCell align="center">{(index + 1) + (pagination.pageNo * pagination.limit)}</TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">
                        <FormControlLabel control={<Switch checked={row.status} color={row.status ? "success" : "error"} />} label={row.status ? "Active" : "Inactive"} onChange={(e) => handleStatus(e.target.checked, row)} />
                    </TableCell>
                    <TableCell align="center">{handleDateTimeDefault(row?.createdAt)}</TableCell>
                    <TableCell align="center" component="th" scope="row">
                        <IconButtonIcons
                            title="Edit"
                            IconComponent={EditIcon}
                            color="info"
                            onClick={() => edit(row, "Edit")}
                        />
                        <IconButtonIcons
                            title="View"
                            IconComponent={EyeIcon}
                            color="info"
                            onClick={() => edit(row, "View")}
                        />
                        {
                            (isAuth().roles === "admin" || isAuth().roles === "sanstha")
                                ?
                                <IconButtonIcons
                                    title="Delete"
                                    IconComponent={DeleteIcon}
                                    color="info"
                                    onClick={() => deleteManage(row._id, row.converted)}
                                />
                                :
                                null
                        }
                    </TableCell>
                </TableRow>
            )
        })
    ) : (
        <TableRow sx={{ "td, th": { border: 0, padding: "10px" } }}>
            <TableCell colSpan={5} align="center">Data not found</TableCell>
        </TableRow>
    );

    return (
        <TableCommon columns={columns} tableData={renderTableData} count={count} loading={loading || fetching} pagination={pagination} handlePagination={handlePagination} />
    )
}

export default GotraTableComponent;