import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { changeContactInitialState, changeSansthaId } from "../../../store/cms/cmsSlice";
import sansthaApis from "../../../store/sanstha/sansthaApis";

const mapStateToProps = (state) => {
    return {
        contactInitialValues: state.cms.contactInitialValues,
        sansthaId: state.cms.sansthaId,
    };
};

const mapDispatch = {
    changeContactInitialState,
    changeSansthaId,
    getSansthaList: sansthaApis.endpoints.getSansthaList.initiate,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(mapDispatch, dispatch);

const Store = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;