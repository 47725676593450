import React, { Fragment, useState } from "react";
import EyeIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Checkbox, Grid, Stack, TableCell, TableRow, Typography } from "@mui/material";
import IconButtonIcons from "../../Common/IconButtonIcons";
import TableCommon from "../../Common/Table";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { baseUrl } from "../../../helpers/constants";
import { useDeleteMembersMutation, useUpdateSansthaMembersMutation } from "../../../store/members/membersApis";
import { isAuth } from "../../../helpers/cookies";
import { handleDateTimeDefault } from "../../../helpers/utils";
import AutoCompleteSelect from "../../Common/AutoCompleteSelect";

const MembersTableComponent = ({ loading, fetching = false, count, data, edit, convert, pagination, handlePagination, sansthaList }) => {
    const [selected, setSelected] = React.useState([]);
    const [sansthaId, setSansthaId] = useState([]);

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = data.map((n) => n._id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const [handleDelete] = useDeleteMembersMutation();
    const [handleSansthaUpdate] = useUpdateSansthaMembersMutation();
    let columns = [
        {
            title: "Sr. No.",
            sort: false,
            minWidth: 50,
        },
        {
            title: "Sanstha",
            sort: false,
            minWidth: 170,
            hide: (isAuth().roles !== "admin")
        },
        {
            title: "Name",
            field: "name",
            sort: true,
            minWidth: 170,
        },
        {
            title: "Mobile",
            field: "mobile",
            sort: true,
            minWidth: 170,
        },
        {
            title: "Work Status",
            field: "work_status",
            sort: true,
            minWidth: 170,
        },
        {
            title: "Photo",
            field: "profile",
            sort: false,
            minWidth: 50,
        },
        {
            title: "Member Status",
            field: "member_status",
            sort: true,
            minWidth: 170,
        },
        {
            title: "Action",
            name: "",
            sort: false,
            minWidth: 200,
        },
    ];

    const deleteManage = (_id, converted) => {
        if (window.confirm("Are you sure you want to delete?")) {
            handleDelete({ _id });
        }
    }

    const handleUpdateSanstha = () => {
        if (window.confirm("Are you sure you want to assign these sanstha to selected member?")) {
            handleSansthaUpdate({ sanstha_ids: sansthaId, member_ids: selected });
            setSansthaId([]);
            setSelected([]);
        }
    }

    const renderTableData = !loading && data && data.length > 0 ? (
        data.map(function (row, index) {
            const isItemSelected = isSelected(row._id);
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
                <TableRow
                    key={index}
                    sx={{ "td, th": { border: 0, padding: "10px", cursor: 'pointer' } }}
                    {...(isAuth().roles === "admin" && { onClick: (event) => handleClick(event, row._id), role: "checkbox", "aria-checked": isItemSelected, selected: isItemSelected })}
                >
                    {isAuth().roles === "admin" && !loading && (
                        <TableCell padding="checkbox">
                            <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                    'aria-labelledby': labelId,
                                }}
                            />
                        </TableCell>
                    )}

                    <TableCell align="center">{(index + 1) + (pagination.pageNo * pagination.limit)}</TableCell>

                    {
                        (isAuth().roles === "admin")
                            ?
                            <TableCell align="center">{row?.sanstha_id && row?.sanstha_id.length > 0 ? row?.sanstha_id.map((obj) => obj.name).join(", ") : (row?.sanstha_id?.name || "-")}</TableCell>
                            :
                            null
                    }
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row?.mobile}</TableCell>
                    <TableCell align="center">{row?.work_status}</TableCell>
                    <TableCell align="center">
                        <img src={baseUrl + "/" + row.profile} alt="Profile" width={50} />
                    </TableCell>
                    <TableCell align="center">{row?.member_status}</TableCell>
                    <TableCell align="center" component="th" scope="row" onClick={(e) => e.stopPropagation()}>
                        <IconButtonIcons
                            title="Edit"
                            IconComponent={EditIcon}
                            color="info"
                            onClick={() => edit(row, "Edit")}
                        />
                        <IconButtonIcons
                            title="View"
                            IconComponent={EyeIcon}
                            color="info"
                            onClick={() => edit(row, "View")}
                        />
                        {
                            (isAuth().roles === "admin")
                                ?
                                <IconButtonIcons
                                    title="Delete"
                                    IconComponent={DeleteIcon}
                                    color="info"
                                    onClick={() => deleteManage(row._id, row.converted)}
                                />
                                :
                                null
                        }

                        {
                            !row.converted && (isAuth().roles === "admin")
                                ?
                                <IconButtonIcons
                                    title="Convert to User"
                                    IconComponent={AutorenewIcon}
                                    color="info"
                                    onClick={() => convert(row._id)}
                                />
                                :
                                null
                        }
                    </TableCell>
                </TableRow>
            )
        })
    ) : (
        <TableRow sx={{ "td, th": { border: 0, padding: "10px" } }}>
            <TableCell colSpan={7} align="center">Data not found</TableCell>
        </TableRow>
    );

    return (
        <Grid container spacing={2}>
            {
                selected.length > 0 && (
                    <Fragment>
                        <Grid item xs={12} md={6} lg={6}>
                            <Typography variant="h6">{selected.length} Selected</Typography>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} display={"flex"} flexDirection={"row"} gap={2}>
                            <AutoCompleteSelect
                                label="Assign sanstha to members *"
                                name="sanstha_id"
                                id="sanstha_id"
                                fullWidth={false}
                                options={sansthaList !== undefined ? sansthaList : []}
                                onChange={(e, val) => setSansthaId(val)}
                                value={sansthaId}
                                isMultiple={true}
                            />
                            {
                                sansthaId.length > 0 && (
                                    <Button onClick={() => handleUpdateSanstha()} variant="contained" color="success">Update</Button>
                                )
                            }
                        </Grid>
                    </Fragment>
                )
            }
            <Grid item xs={12} md={12}>
                <TableCommon columns={columns} tableData={renderTableData} count={count} loading={loading || fetching} pagination={pagination} handlePagination={handlePagination} checkbox={isAuth().roles === "admin" && true} numSelected={selected.length} onSelectAllClick={handleSelectAllClick} />
            </Grid>
        </Grid >
    )
}

export default MembersTableComponent;