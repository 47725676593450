import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from "../../helpers/constants";
import { getCookie } from "../../helpers/cookies";

const cmsApis = createApi({
    reducerPath: 'cmsApis',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getCookie("token");
            if (token) {
                headers.set('Authorization', `BEARER ${token}`)
            }
            return headers;
        }
    }),
    keepUnusedDataFor: 0,
    tagTypes: ["Home", "About", "Contact"],
    endpoints: build => ({
        getHomePageCms: build.query({
            query: (params) => ({
                url: "/cms/home-page",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => response.result,
            providesTags: ['Home'],
            keepUnusedDataFor: 0,
        }),
        updateHomePageCMS: build.mutation({
            query: (payload) => ({
                url: "/cms/home-page",
                method: "POST",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Home'],
        }),
        getAboutPageCms: build.query({
            query: (params) => ({
                url: "/cms/about-page",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => response.result,
            providesTags: ['About'],
            keepUnusedDataFor: 0,
        }),
        updateAboutPageCMS: build.mutation({
            query: (payload) => ({
                url: "/cms/about-page",
                method: "POST",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['About'],
        }),
        getContactPageCms: build.query({
            query: (params) => ({
                url: "/cms/contact-page",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => response.result,
            providesTags: ['Contact'],
            keepUnusedDataFor: 0,
        }),
        updateContactPageCMS: build.mutation({
            query: (payload) => ({
                url: "/cms/contact-page",
                method: "POST",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Contact'],
        }),
    }),
});

export const {
    useGetHomePageCmsQuery, useUpdateHomePageCMSMutation,
    useGetAboutPageCmsQuery, useUpdateAboutPageCMSMutation,
    useGetContactPageCmsQuery, useUpdateContactPageCMSMutation,
} = cmsApis;
export default cmsApis;