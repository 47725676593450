import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from "../../helpers/constants";
import { getCookie } from "../../helpers/cookies";
import { candidateListParser } from './candidateParser';

const candidateApis = createApi({
    reducerPath: 'candidateApis',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getCookie("token");
            if (token) {
                headers.set('Authorization', `BEARER ${token}`)
            }
            return headers;
        }
    }),
    keepUnusedDataFor: 0,
    tagTypes: ["Candidate"],
    endpoints: build => ({
        getCandidateList: build.query({
            query: (params) => ({
                url: "/candidate/list",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => candidateListParser(response),
            providesTags: ['Candidate'],
            keepUnusedDataFor: 5,
        }),
        addNewCandidate: build.mutation({
            query: (payload) => ({
                url: "/candidate",
                method: "POST",
                body: payload,
                formData: true
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Candidate'],
        }),
        bulkAddNewCandidate: build.mutation({
            query: (payload) => ({
                url: "/candidate/multiple",
                method: "POST",
                body: payload,
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Candidate'],
        }),
        updateCandidate: build.mutation({
            query: (payload) => ({
                url: "/candidate",
                method: "PUT",
                body: payload,
                formData: true
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Candidate'],
        }),
        deleteCandidate: build.mutation({
            query: (params) => ({
                url: "/candidate",
                method: "DELETE",
                params: params
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Candidate'],
        }),
        searchCandidate: build.query({
            query: (body) => ({
                url: "/candidate/search",
                method: "POST",
                body: body,
            }),
            transformResponse: (response) => candidateListParser(response),
        }),
    }),
});

export const { useGetCandidateListQuery, useAddNewCandidateMutation, useBulkAddNewCandidateMutation, useUpdateCandidateMutation, useDeleteCandidateMutation, useSearchCandidateQuery } = candidateApis;
export default candidateApis;