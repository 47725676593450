import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { changeMastersInitialState, handlePaginationState } from "../../../store/masters/mastersSlice";

const mapStateToProps = (state) => {
    return {
        formType: state.masters.formType,
        initialValues: state.masters.initialValues,
        showDrawer: state.masters.showDrawer,
        pagination: state.masters.pagination,
    };
};

const mapDispatch = {
    changeMastersInitialState,
    handlePaginationState
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(mapDispatch, dispatch);

const Store = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;