import { get } from "lodash-es";

export const gotraListParser = (response) => {
    try {
        if (response?.result) {
            response = response.result;
        }
        if (!response) {
            return [];
        }

        if (response.active) {
            return response.result.map(function (row, key) {
                return {
                    value: get(row, "name", ""),
                    label: get(row, "name", ""),
                }
            });
        }

        response.result = response.result.map(function (row, key) {
            return {
                _id: get(row, "_id", ""),
                name: get(row, "name", ""),
                status: get(row, "status", ""),
                createdAt: get(row, "createdAt", ""),
            }
        });

        return response;

    } catch (error) {
        throw new Error(error);
    }
}

export const qualificationListParser = (response) => {
    try {
        if (response?.result) {
            response = response.result;
        }
        if (!response) {
            return [];
        }
        if (response.active) {
            return response.result.map(function (row, key) {
                return {
                    value: get(row, "name", ""),
                    label: get(row, "name", ""),
                }
            });
        }

        response.result = response.result.map(function (row, key) {
            return {
                _id: get(row, "_id", ""),
                name: get(row, "name", ""),
                status: get(row, "status", ""),
                createdAt: get(row, "createdAt", ""),
            }
        });

        return response;

    } catch (error) {
        throw new Error(error);
    }
}

export const bannerListParser = (response) => {
    try {
        if (response?.result) {
            response = response.result;
        }
        if (!response) {
            return [];
        }

        response.result = response.result.map(function (row, key) {
            return {
                _id: get(row, "_id", ""),
                url: get(row, "url", ""),
                sanstha_id: get(row, "sanstha_id", ""),
                status: get(row, "status", ""),
                type: get(row, "type", ""),
                displayOrder: get(row, "displayOrder", ""),
                createdAt: get(row, "createdAt", ""),
            }
        });

        return response;

    } catch (error) {
        throw new Error(error);
    }
}