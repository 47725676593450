import { Card, CardActions, CardContent, Grid, TablePagination, Typography } from "@mui/material";

import styled from "@emotion/styled";
import { baseUrl } from "../../../helpers/constants";
import { calculateAge, capitalizeFirstLetter } from "../../../helpers/utils";
import Button from "../../Common/Button";
import TablePaginationActions from "../../Common/TablePaginationActions";
import CandidateSearchSkeleton from "../../Common/CandidateSearchSkeleton";

const CustomCard = styled(Card)(
    ({ theme }) => `
    display: inline-flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    border-radius: 15px;
    width: 100%;
    background-image: none;
    background-color: unset;
  `,
);

const MainCard = styled(Card)(
    ({ theme }) => `
    display: block;
    padding: 2.5rem 2rem;
    border-radius: 10px;
    max-width: 500px;
    box-shadow: 0 0 30px rgba(0, 0, 0, .15);
    margin: 1rem;
    position: relative;
    transform-style: preserve-3d;
    overflow: hidden;
  `,
);

// const useStyles = styled((theme) => ({
//     text: {
//         margin: theme.spacing(0, 0, 0.5),
//         //color: theme.palette.secondary.contrastText,
//     },
//     avatar: {
//         verticalAlign: "middle",
//         marginRight: theme.spacing(0.5),
//     },
//     large: {
//         width: theme.spacing(12),
//         height: theme.spacing(12),
//         margin: theme.spacing(2, 2, 0),
//     },
//     card: {
//         borderRadius: 15,
//         maxWidth: "270px",
//         minWidth: "270px",
//         height: "330px",
//         backgroundColor: theme.palette.background.card,
//     },
//     cardContent: {
//         padding: theme.spacing(2, 0, 0, 0),
//     },
// }));

const CandidateCards = ({ loading, count, data, view, pagination, handlePagination, isSuccess = false }) => {
    const paginationRequest = ({ pageNo, limit, sortBy, sortField, keywords }) => {
        handlePagination({
            ...pagination,
            pageNo: pageNo !== undefined ? pageNo : pagination.pageNo,
            limit: limit !== undefined ? limit : pagination.limit,
            sortBy: sortBy || pagination.sortBy,
            sortField: sortField || pagination.sortField,
            keywords: keywords || pagination.keywords,
        })
    }

    const renderCard = !loading && data && data.length > 0 ? (
        data.map(function (row, index) {
            return (
                <Grid item xs={4} sm={4} md={4}>
                    <MainCard className="profile-card" >
                        <CustomCard>
                            <div class="img">
                                <img src={baseUrl + "/" + ((row.profile.length > 0 && row.profile[0]) || "public/no-image.png")} alt="profile-img" />
                            </div>
                            <CardContent className="infos">
                                <Typography variant="h6" component="div" className="name">
                                    <h2 >{row?.name}</h2>
                                </Typography>
                                <ul class="stats">
                                    <li>
                                        <h3> {capitalizeFirstLetter(row?.gender) || "-"}</h3>
                                        <h4>Gender</h4>
                                    </li>
                                    <li>
                                        <h3>{row?.height && row?.height.split(".").length > 0 ? row?.height.split(".")[0] + '"' + row?.height.split(".")[1] + "'" : "-"}</h3>
                                        <h4>Height</h4>
                                    </li>
                                    <li>
                                        <h3>{row?.age || calculateAge(new Date(row?.dob)) || "-"}</h3>
                                        <h4>Age</h4>
                                    </li>
                                </ul>
                            </CardContent>
                        </CustomCard>
                        <div className="otherinfos">
                            <Typography variant="subtitle2">
                                Qualification: {row?.qualification || "N/A"}
                            </Typography>
                            <Typography variant="subtitle2">
                                Location: {`${row?.city || row.state || row.zipcode ? row?.city + ", " + row.state + ", " + row.zipcode : "N/A"}`}
                            </Typography>
                        </div>
                        <CardActions sx={{ justifyContent: "end" }}>
                            <Button size="small" fullWidth={false} onClick={() => view(row)}>View Profile</Button>
                        </CardActions>
                    </MainCard>
                </Grid >
            )
        })
    ) : (
        <></>
    );

    return (
        <>
            {
                loading
                    ?
                    <CandidateSearchSkeleton />
                    :
                    null
            }
            {
                pagination && !loading && data && data.length > 0
                    ?
                    <Grid item xs={12} gap={2} textAlign={"left"}>
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[12, 36, 60, { label: 'All', value: count }]}
                            count={count}
                            rowsPerPage={pagination.limit}
                            page={pagination.pageNo}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={(e, newNo) => paginationRequest({ pageNo: newNo })}
                            onRowsPerPageChange={(e) => paginationRequest({ limit: e.target.value })}
                            ActionsComponent={TablePaginationActions}
                        />
                    </Grid>
                    :
                    null
            }
            {renderCard}
            {
                data && data.length === 0 && isSuccess
                    ?
                    <Grid item xs={12} sm={12} md={12} textAlign={"center"}>
                        <Typography variant="h6">No Record found, please search something else.</Typography>
                    </Grid>
                    :
                    null
            }
            {
                pagination && !loading && data && data.length > 0
                    ?
                    <Grid item xs={12} gap={2} textAlign={"left"}>
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[12, 36, 60, { label: 'All', value: count }]}
                            count={count}
                            rowsPerPage={pagination.limit}
                            page={pagination.pageNo}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={(e, newNo) => paginationRequest({ pageNo: newNo })}
                            onRowsPerPageChange={(e) => paginationRequest({ limit: e.target.value })}
                            ActionsComponent={TablePaginationActions}
                        />
                    </Grid>
                    :
                    null
            }
        </>
    );
}

export default CandidateCards;