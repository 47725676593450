import * as yup from "yup";

export const MembersValidation = yup.object().shape({
    sanstha_id: yup.array().required("Required"),
    name: yup.string().required("Required"),
    mobile: yup.string().required("Required"),
    status: yup.string().required("Required"),
});

export const FamilyMembersValidation = yup.object().shape({
    name: yup.string().required("Required"),
    email: yup.string(),
    gender: yup.string(),
    mobile: yup.string(),
    dob: yup.string(),
    relation: yup.string(),
});