import React, { useState } from "react";
import { FormControlLabel, Grid, Input, Switch, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { setSnackBar } from "../../../store/common/commonSlice";
import { calculateAge, convertCSVToJson } from "../../../helpers/utils";
import { useBulkAddNewCandidateMutation } from "../../../store/candidate/candidateApis";
import AutoCompleteServerSide from "../../Common/AutoCompleteServerSide";
import Button from "../../Common/Button";
import { baseUrl } from "../../../helpers/constants";

export const BulkImportCandidates = ({ getSansthaList }) => {
    const dispatch = useDispatch();
    const [BulkAddCandidate, { isLoading: importLoading }] = useBulkAddNewCandidateMutation();
    const [reportsData, setReportData] = useState(null);
    const [sansthaId, setSansthaId] = useState(null);
    const [showToEveryone, setShowToEveryone] = useState(true);

    const handleFileReading = async (e) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const reader = new FileReader();

            reader.onload = async (e) => {
                const csvData = e.target.result;
                const jsonData = convertCSVToJson(csvData);

                if (jsonData && jsonData.length > 0) {
                    setReportData(jsonData);
                }
            };

            reader.readAsText(files[0]);
        }
    }

    const handleFileUpload = async () => {
        try {
            if (!reportsData)
                return false;


            let payload = reportsData.map((obj) => {
                return {
                    ...obj,
                    gender: obj.gender.toLowerCase() || "male",
                    age: calculateAge(new Date(obj?.dob)),
                    sanstha_id: sansthaId,
                    show_to_everyone: showToEveryone
                }
            })

            await BulkAddCandidate(payload).unwrap();
            dispatch(setSnackBar({
                open: true,
                message: "Data imported successfully.",
                severity: "success",
            }))

            document.getElementById("importfile").value = "";
            setReportData(null);
            setSansthaId(null);
        } catch (error) {
            dispatch(setSnackBar({
                open: true,
                message: error?.data?.message || error.message,
                severity: "error",
            }))
        }
    };

    return (
        <Grid container spacing={2} pt={2}>
            <Grid item xs={12} md={4}>
                <AutoCompleteServerSide
                    label="Type & Select Sanstha *"
                    name="sanstha_id"
                    id="sanstha_id"
                    fullWidth
                    defaultValue={sansthaId}
                    fetchDataFunction={(d) => getSansthaList(d)}
                    onChange={(val) => setSansthaId(val)}
                    disabled={false}
                    isMultiple={false}
                />
            </Grid>
            <Grid item xs={12} md={4} >
                <Input type="file" name="file" id="importfile" accept=".csv,.xlsx,.xls" onChange={(e) => handleFileReading(e)} />
                <Typography variant="caption">Download sample file <a rel="noreferrer" href={baseUrl + "/public/sample/member-import-sample.csv"} target="_blank">here</a></Typography><br />
                <Typography variant="caption">Be carefull with the data you add in excel file.</Typography><br />
                <Typography variant="caption">Try not to upload large amount of records at once.</Typography>
            </Grid>
            <Grid item xs={12} md={4} >
                <FormControlLabel control={<Switch defaultChecked />} label="Show to everyone" onChange={(e) => setShowToEveryone(e.target.checked)} />
            </Grid>
            <Grid item xs={12}>
                <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Grid sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <Button size="large" type="submit" loading={importLoading} onClick={() => handleFileUpload()}>Save</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}