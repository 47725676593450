import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { PhotoGalleryValidation } from "./PhotoGalleryValidation";
import { Drawer, FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseOutlined"
import IconButtonIcons from "../../Common/IconButtonIcons";
import Input from "../../Common/Input";
import Button from "../../Common/Button";
import { useDispatch } from "react-redux";
import { setSnackBar } from "../../../store/common/commonSlice";
import { FileUploader } from "react-drag-drop-files";
import { fileTypes } from "../../../helpers/constants";
import ImagesGallery from "../candidate-manager/ImagesGallery";
import { useAddNewGalleryMutation } from "../../../store/masters/mastersApis";
import AutoCompleteServerSide from "../../Common/AutoCompleteServerSide";

export const PhotoGalleryAddDrawer = ({ initialValues, show, close, formType, getSansthaList }) => {
    const dispatch = useDispatch();
    const [addNewGallery, { isLoading: addGalleryLoading }] = useAddNewGalleryMutation();
    const [files, setFiles] = useState([]);
    const [images, setImages] = useState([]);

    useEffect(() => {
        setImages([])
    }, [initialValues])

    const handleFileChange = (evnt) => {
        const selectedFIles = [];
        setFiles(evnt);
        const targetFilesObject = [...evnt];
        targetFilesObject.map((file) => {
            return selectedFIles.push(URL.createObjectURL(file))
        })
        setImages(prev => [...selectedFIles, ...prev]);
    };

    const removeImage = (key) => {
        setImages(prevItems => prevItems.filter((item, index) => index !== key));
    }

    const onFormSubmit = async (values, { resetForm }) => {
        try {
            const formData = new FormData();

            Object.keys(values).forEach(function (key, index) {
                if (key === "category") {
                    formData.append(key, values[key]);
                } else {
                    formData.append(key, JSON.stringify(values[key]));
                }
            });

            formData.append("type", "image");

            for (const file of files) {
                formData.append("images", file);
            }

            await addNewGallery(formData).unwrap();
            dispatch(setSnackBar({
                open: true,
                message: "Gallery created successfully",
                severity: "success",
            }))
            close();
            resetForm();
        } catch (error) {
            dispatch(setSnackBar({
                open: true,
                message: error?.data?.message || error.message,
                severity: "error",
            }))
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, action) => onFormSubmit(values, action)}
            validationSchema={PhotoGalleryValidation}
            enableReinitialize
        >
            {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                setFieldValue
            }) => (
                <Drawer
                    anchor={"right"}
                    open={show}
                    PaperProps={{
                        sx: { width: { xs: '100%', md: '70%', sm: "70%", lg: "70%" } },
                    }}
                    onClose={() => close()}
                >
                    {console.log(values, "values")}
                    <Grid sx={{ display: "flex" }} direction={"column"} width={"100%"} height={"100%"} >
                        <Grid container flex={0} px={1} py={1} borderBottom={1} borderColor={"rgba(5, 5, 5, 0.06)"}>
                            <Grid item alignSelf={"center"}>
                                <IconButtonIcons color="default" title="Close" IconComponent={CloseIcon} onClick={() => close()} />
                            </Grid>
                            <Grid item alignSelf={"center"}>
                                <Typography variant="h6">{formType} Photo Gallery</Typography>
                            </Grid>
                        </Grid>
                        <Grid flex={1} px={2} py={5} overflow={"auto"}>
                            <Grid container spacing={2} >
                                <Grid item xs={12} md={6}>
                                    <AutoCompleteServerSide
                                        label="Type & Select Sanstha *"
                                        name="sanstha_id"
                                        id="sanstha_id"
                                        fullWidth
                                        fetchDataFunction={(d) => getSansthaList(d)}
                                        onChange={(val) => setFieldValue("sanstha_id", val)}
                                        defaultValue={values?.sanstha_id || null}
                                        error={Boolean(errors.sanstha_id)}
                                        helperText={errors.sanstha_id}
                                        isMultiple={true}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='category'
                                        name="category"
                                        label="Category Name"
                                        onChange={handleChange("category")}
                                        value={values?.category || ""}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <FileUploader
                                        classes="drop_area"
                                        handleChange={handleFileChange}
                                        name="file"
                                        types={fileTypes}
                                        label="Upload or drop all files at once right here"
                                        multiple={true}
                                    />

                                    <ImagesGallery images={images} remove={(key) => removeImage(key)} />
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                            formType !== 'View'
                                ?
                                <Grid flexShrink={0} borderTop={1} borderColor={"rgba(152, 188, 252, 0.16)"} sx={{ padding: "8px 16px" }}>
                                    <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Grid sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                            <Button size="large" color="warning" type="button" onClick={() => close()}>Cancel</Button>
                                            <Button size="large" type="submit" loading={addGalleryLoading} onClick={() => handleSubmit()}>Save</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                :
                                null
                        }

                    </Grid>
                </Drawer>
            )
            }
        </Formik >
    )
}