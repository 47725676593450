import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { changeSansthaInitialState, handlePaginationState } from "../../../store/sanstha/sansthaSlice";

const mapStateToProps = (state) => {
    return {
        formType: state.sanstha.formType,
        initialValues: state.sanstha.initialValues,
        showDrawer: state.sanstha.showDrawer,
        pagination: state.sanstha.pagination,
    };
};

const mapDispatch = {
    changeSansthaInitialState,
    handlePaginationState
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(mapDispatch, dispatch);

const Store = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;