import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { changeCandidateInitialState, changeCandidateSearchInitialState } from "../../../store/candidate/candidateSlice";

const mapStateToProps = (state) => {
    return {
        formType: state.candidate.formType,
        initialValues: state.candidate.initialValues,
        showDrawer: state.candidate.showDrawer,
        candidateSearchInitialVal: state.candidate.candidateSearchInitialVal,
        pagination: state.candidate.pagination,
    };
};

const mapDispatch = {
    changeCandidateInitialState,
    changeCandidateSearchInitialState,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(mapDispatch, dispatch);

const Store = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;