import React from "react";

import BaseLayout from "../../components/layout/BaseLayout";
import AdminRoutes from "../../routes/AdminRoutes";
import HomeIcon from "@mui/icons-material/Home";
import UserIcon from '@mui/icons-material/Person2Outlined';
import SansthaIcon from '@mui/icons-material/Groups';
import MembersIcon from '@mui/icons-material/Groups3';
import { withTheme } from "@emotion/react";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import SearchIcon from '@mui/icons-material/Search';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import CollectionsIcon from '@mui/icons-material/Collections';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import AssessmentIcon from '@mui/icons-material/Assessment';

class LayoutContainer extends React.Component {

    render() {

        return (
            <BaseLayout
                params={this.props.params}
                modules={adminModules}
                ChildComponent={AdminRoutes}
            />
        );
    }
}
export default withTheme(LayoutContainer);

const adminModules = [
    {
        id: "dashboard",
        title: "Dashboard",
        link: "/dashboard",
        role: ["admin", "sanstha", "users"],
        logo: <HomeIcon />,
    },
    {
        id: "user-manager",
        title: "User Manager",
        link: "/user-manager",
        logo: <UserIcon />,
        role: ["admin"]
    },
    {
        id: "sanstha-manager",
        title: "Sanstha Manager",
        link: "/sanstha-manager",
        logo: <SansthaIcon />,
        role: ["admin"]
    },
    {
        id: "members-manager",
        title: "Member Manager",
        link: "/members-manager",
        logo: <MembersIcon />,
        role: ["admin", "sanstha", "users"]
    },
    {
        id: "candidate-manager",
        title: "Candidate Manager",
        link: "/candidate-manager",
        logo: <AssignmentIndIcon />,
        role: ["admin", "sanstha", "users"]
    },
    {
        id: "advance-search",
        title: "Advance Search",
        link: "/advance-search",
        logo: <ScreenSearchDesktopIcon />,
        role: ["admin", "sanstha", "users"]
    },
    {
        id: "candidate-search",
        title: "Normal Search",
        link: "/candidate-search",
        logo: <SearchIcon />,
        role: ["admin", "sanstha", "users"]
    },
    {
        id: "masters-search",
        title: "CRUD Master",
        logo: <DynamicFeedIcon />,
        role: ["admin"],
        items: [
            {
                logo: <ViewCarouselIcon />,
                id: "banner-master",
                title: "Banner Master",
                role: ["admin"],
                link: "/banner-master",
            },
            {
                logo: <AcUnitIcon />,
                id: "gotra-master",
                title: "Gotra Master",
                role: ["admin"],
                link: "/gotra-master",
            },
            {
                logo: <CastForEducationIcon />,
                id: "qualification-master",
                title: "Qualification Master",
                role: ["admin"],
                link: "/qualification-master",
            },
            {
                logo: <CollectionsIcon />,
                id: "photo-gallery-master",
                title: "Photo Gallery Master",
                role: ["admin"],
                link: "/photo-gallery-master",
            },
            {
                logo: <OndemandVideoIcon />,
                id: "video-gallery-master",
                title: "Video Gallery Master",
                role: ["admin"],
                link: "/video-gallery-master",
            },
        ]
    },
    {
        id: "cms",
        title: "CMS",
        logo: <DynamicFeedIcon />,
        role: ["admin"],
        items: [
            {
                logo: <AcUnitIcon />,
                id: "home-page-cms",
                title: "Home Page",
                role: ["admin"],
                link: "/home-page-cms",
            },
            {
                logo: <AcUnitIcon />,
                id: "about-page-cms",
                title: "About Page",
                role: ["admin"],
                link: "/about-page-cms",
            },
            {
                logo: <AcUnitIcon />,
                id: "contact-page-cms",
                title: "Contact Page",
                role: ["admin"],
                link: "/contact-page-cms",
            },
        ]
    },
    {
        id: "leads",
        title: "Leads",
        link: "/leads",
        logo: <AssessmentIcon />,
        role: ["admin"]
    },
    {
        id: "contact-leads",
        title: "Contact Leads",
        link: "/contact-leads",
        logo: <AssessmentIcon />,
        role: ["admin", "sanstha"]
    },
];
