import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from "../../helpers/constants";
import { getCookie } from "../../helpers/cookies";
import { membersListParser } from './membersParser';

const membersApis = createApi({
    reducerPath: 'membersApis',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getCookie("token");
            if (token) {
                headers.set('Authorization', `BEARER ${token}`)
            }
            return headers;
        }
    }),
    keepUnusedDataFor: 0,
    tagTypes: ["Members"],
    endpoints: build => ({
        getMembersList: build.query({
            query: (params) => ({
                url: "/sanstha/members/list",
                method: "GET",
                params: { ...params, from_portal: "true" }
            }),
            transformResponse: (response) => membersListParser(response),
            providesTags: ['Members'],
            keepUnusedDataFor: 5,
        }),
        addNewMembers: build.mutation({
            query: (payload) => ({
                url: "/sanstha/members",
                method: "POST",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Members'],
        }),
        bulkAddNewMembers: build.mutation({
            query: (payload) => ({
                url: "/sanstha/members-multiple",
                method: "POST",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Members'],
        }),
        updateMembers: build.mutation({
            query: (payload) => ({
                url: "/sanstha/members",
                method: "PUT",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Members'],
        }),
        updateSansthaMembers: build.mutation({
            query: (payload) => ({
                url: "/sanstha/update-sanstha-members",
                method: "PUT",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Members'],
        }),
        deleteMembers: build.mutation({
            query: (params) => ({
                url: "/sanstha/members",
                method: "DELETE",
                params: params
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Members'],
        }),
    }),
});

export const { useGetMembersListQuery, useAddNewMembersMutation, useBulkAddNewMembersMutation, useUpdateMembersMutation, useUpdateSansthaMembersMutation, useDeleteMembersMutation } = membersApis;
export default membersApis;