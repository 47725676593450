import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from "../../helpers/constants";
import { getCookie } from "../../helpers/cookies";
import { gotraListParser, qualificationListParser, bannerListParser } from './mastersParser';

const mastersApis = createApi({
    reducerPath: 'mastersApis',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getCookie("token");
            if (token) {
                headers.set('Authorization', `BEARER ${token}`)
            }
            return headers;
        }
    }),
    keepUnusedDataFor: 0,
    tagTypes: ["Gotra", "Qualification", "Gallery", "Banner"],
    endpoints: build => ({
        getGotraList: build.query({
            query: (params) => ({
                url: "/masters/gotra/list",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => gotraListParser(response),
            providesTags: ['Gotra'],
            keepUnusedDataFor: 5,
        }),
        addNewGotra: build.mutation({
            query: (payload) => ({
                url: "/masters/gotra",
                method: "POST",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Gotra'],
        }),
        updateGotra: build.mutation({
            query: (payload) => ({
                url: "/masters/gotra",
                method: "PUT",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Gotra'],
        }),
        deleteGotra: build.mutation({
            query: (params) => ({
                url: "/masters/gotra",
                method: "DELETE",
                params: params
            }),
            transformResponse: (response) => response.data,
            transformErrorResponse: (response) => response.data,
            invalidatesTags: ['Gotra'],
        }),
        // qualification
        getQualificationList: build.query({
            query: (params) => ({
                url: "/masters/qualification/list",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => qualificationListParser(response),
            providesTags: ['Qualification'],
            keepUnusedDataFor: 5,
        }),
        addNewQualification: build.mutation({
            query: (payload) => ({
                url: "/masters/qualification",
                method: "POST",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Qualification'],
        }),
        updateQualification: build.mutation({
            query: (payload) => ({
                url: "/masters/qualification",
                method: "PUT",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Qualification'],
        }),
        deleteQualification: build.mutation({
            query: (params) => ({
                url: "/masters/qualification",
                method: "DELETE",
                params: params
            }),
            transformResponse: (response) => response.data,
            transformErrorResponse: (response) => response.data,
            invalidatesTags: ['Qualification'],
        }),
        // gallery
        getGalleryList: build.query({
            query: (params) => ({
                url: "/masters/gallery",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => response.result,
            providesTags: ['Gallery'],
            keepUnusedDataFor: 5,
        }),
        highlightGallery: build.mutation({
            query: (params) => ({
                url: "/masters/gallery-highlighted",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Gallery'],
        }),
        addNewGallery: build.mutation({
            query: (payload) => ({
                url: "/masters/gallery",
                method: "POST",
                body: payload,
                formData: true
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Gallery'],
        }),
        deleteGallery: build.mutation({
            query: (params) => ({
                url: "/masters/gallery",
                method: "DELETE",
                params: params
            }),
            transformResponse: (response) => response.data,
            transformErrorResponse: (response) => response.data,
            invalidatesTags: ['Gallery'],
        }),
        // banner
        getBannerList: build.query({
            query: (params) => ({
                url: "/masters/banner/list",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => bannerListParser(response),
            providesTags: ['Banner'],
            keepUnusedDataFor: 5,
        }),
        addNewBanner: build.mutation({
            query: (payload) => ({
                url: "/masters/banner",
                method: "POST",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Banner'],
        }),
        updateBanner: build.mutation({
            query: (payload) => ({
                url: "/masters/banner",
                method: "PUT",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Banner'],
        }),
        deleteBanner: build.mutation({
            query: (params) => ({
                url: "/masters/banner",
                method: "DELETE",
                params: params
            }),
            transformResponse: (response) => response.data,
            transformErrorResponse: (response) => response.data,
            invalidatesTags: ['Banner'],
        }),
    }),
});

export const {
    useGetGotraListQuery, useAddNewGotraMutation, useUpdateGotraMutation, useDeleteGotraMutation,
    useGetQualificationListQuery, useAddNewQualificationMutation, useUpdateQualificationMutation, useDeleteQualificationMutation,
    useGetGalleryListQuery, useHighlightGalleryMutation, useAddNewGalleryMutation, useDeleteGalleryMutation,
    useGetBannerListQuery, useAddNewBannerMutation, useUpdateBannerMutation, useDeleteBannerMutation,
} = mastersApis;
export default mastersApis;