import React from "react";
import { Formik } from "formik";
import { AboutPageValidation } from "./validation";
import { FormControl, FormHelperText, Grid, Paper, Typography } from "@mui/material";
import Button from "../../Common/Button";
import { useDispatch } from "react-redux";
import { setSnackBar } from "../../../store/common/commonSlice";
import Input from "../../Common/Input";
import { CommonFileUploadToServer } from "../../Common/CommonFileUploadToServer";
import RichTextEditor from "../../Common/editor/RichTextEditor";
import { useUpdateAboutPageCMSMutation } from "../../../store/cms/cmsApis";

export const AboutPageCmsForm = ({ aboutInitialValues, sansthaId }) => {
    const dispatch = useDispatch();
    const [updateAboutPage] = useUpdateAboutPageCMSMutation();

    const onFormSubmit = async (values) => {
        try {
            let payload = { ...values };
            delete payload.createdAt;
            delete payload.updatedAt;
            payload.sanstha_id = sansthaId;
            await updateAboutPage(payload).unwrap();
            dispatch(setSnackBar({
                open: true,
                message: "Data updated successfully",
                severity: "success",
            }))
        } catch (error) {
            dispatch(setSnackBar({
                open: true,
                message: error?.data?.message || error.message,
                severity: "error",
            }))
        }
    };

    return (
        <Formik
            initialValues={aboutInitialValues}
            onSubmit={(values) => onFormSubmit(values)}
            validationSchema={AboutPageValidation}
            enableReinitialize
        >
            {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                setFieldValue
            }) => (
                <Paper
                    sx={{ p: 2, color: "#071B2A", fontWeight: "400" }}
                    elevation={0}
                >
                    <Grid sx={{ display: "flex" }} direction={"column"} width={"100%"} height={"100%"} >
                        <Grid flex={1} px={2} py={5} overflow={"auto"}>
                            <Grid container spacing={2} >
                                <Grid item xs={12} md={12}>
                                    <Typography variant="h6">Home About Us CMS</Typography>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Input
                                        id='about_title'
                                        name="about_title"
                                        label="About Title"
                                        onChange={handleChange("about_title")}
                                        value={values?.about_title || ""}
                                        error={Boolean(errors.about_title)}
                                        helperText={errors.about_title}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <FormControl fullWidth error={Boolean(errors.about_description)}>
                                        <RichTextEditor
                                            placeholder="Home about description"
                                            class="h-20"
                                            setValue={(d) => setFieldValue("about_description", d)}
                                            defaultValue={values?.about_description || ""}
                                        />
                                        {Boolean(errors.about_description) ? (
                                            <FormHelperText>{errors.about_description}</FormHelperText>
                                        ) : null}
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid flexShrink={0} borderTop={1} borderColor={"rgba(152, 188, 252, 0.16)"} sx={{ padding: "8px 16px" }}>
                            <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                                <Grid sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    <Button size="large" type="submit" loading={false} onClick={() => handleSubmit()}>Save</Button>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Paper>
            )
            }
        </Formik >
    )
}