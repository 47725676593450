import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import CandidateTableComponent from "../../../components/admin/candidate-manager/CandidateTable";
import { CandidateAddDrawer } from "../../../components/admin/candidate-manager/CandidateAddDrawer";
import { useGetCandidateListQuery } from "../../../store/candidate/candidateApis";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BulkImportCandidates } from "../../../components/admin/candidate-manager/BulkImportCandidates";
import { isAuth } from "../../../helpers/cookies";
import { SearchRecords } from "../../../components/Common/SearchRecords";
import { Add } from "@mui/icons-material";

const CandidateManagerContainer = (props) => {
    const { showDrawer, formType, initialValues, normalPagination } = props;
    const { isLoading, data: candidate, isFetching } = useGetCandidateListQuery(normalPagination);

    const handleAddCandidate = () => {
        props.changeCandidateInitialState({
            showDrawer: true,
            formType: "Add"
        })
    }

    const handlePagination = (setting) => {
        props.handleNormalPaginationState(setting);
    }

    const handleDrawerClose = () => {
        props.changeCandidateInitialState({ showDrawer: false, formType: "", initialValues: null })
    }

    const handleCandidateEdit = (data, type) => {
        let payload = { ...data };
        delete payload.createdAt;
        props.changeCandidateInitialState({ showDrawer: true, formType: type, initialValues: payload })
    }

    return (
        <Stack spacing={1}>
            <Paper sx={{ marginBottom: "24px", padding: 1.5 }}>
                <Grid container justifyContent="space-between">
                    <Grid item sx={{ alignSelf: "center" }}>
                        <Typography variant="h6">List of Candidate</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            disableElevation
                            variant="contained"
                            sx={{ borderRadius: "50px" }}
                            onClick={() => handleAddCandidate()}
                            startIcon={<Add />}
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>
            </Paper>

            {
                isAuth().roles === "admin"
                    ?
                    <Paper sx={{ marginBottom: "24px !important", padding: 1.5, mb: 0 }}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{ minHeight: "fit-content", pl: 0 }}
                            >
                                <Typography variant="h6">Import Bulk Candidate</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <BulkImportCandidates getSansthaList={props.getSansthaList} />
                            </AccordionDetails>
                        </Accordion>
                    </Paper>
                    :
                    null
            }

            <Grid container>
                <SearchRecords handlePagination={handlePagination} pagination={normalPagination} />
                <Grid item xs={12}>
                    <Paper
                        elevation={0}
                    >
                        <CandidateTableComponent edit={(val, type) => handleCandidateEdit(val, type)} loading={isLoading} fetching={isFetching} count={candidate?.count || 0} data={candidate?.result || []} pagination={normalPagination} handlePagination={(val) => handlePagination(val)} />
                    </Paper>
                </Grid>
            </Grid>

            {/* add taf drawer */}
            <CandidateAddDrawer show={showDrawer} close={handleDrawerClose} formType={formType} initialValues={initialValues} getSansthaList={props.getSansthaList} />

        </Stack >
    );
}

export default CandidateManagerContainer;