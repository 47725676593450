import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showDrawer: false,
    formType: "",
    initialValues: {
        name: "",
        email: "",
        mobile: "",
        status: true
    },
    pagination: {
        pageNo: 0,
        limit: 10,
        sortBy: -1,
        sortField: "createdAt",
        keywords: ""
    }
};

export const sansthaSlice = createSlice({
    name: "sanstha",
    initialState,
    reducers: {
        changeSansthaInitialState: (state, action) => {
            const { showDrawer, formType, initialValues } = action.payload;

            state.showDrawer = showDrawer;
            if (formType)
                state.formType = formType;
            if (initialValues)
                state.initialValues = initialValues;
        },
        handlePaginationState: (state, action) => {
            const { payload } = action;
            state.pagination = payload;
        }
    },
});

// Action creators are generated for each case reducer function
export const { changeSansthaInitialState, handlePaginationState } = sansthaSlice.actions;

export default sansthaSlice;
