import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { changeUserInitialState, handlePaginationState } from "../../../store/user/userSlice";

const mapStateToProps = (state) => {
    return {
        formType: state.user.formType,
        initialValues: state.user.initialValues,
        showDrawer: state.user.showDrawer,
        pagination: state.user.pagination,
    };
};

const mapDispatch = {
    changeUserInitialState,
    handlePaginationState
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(mapDispatch, dispatch);

const Store = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;