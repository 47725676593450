import React, { useEffect } from "react";
import { useGetUserListQuery } from "../../../store/user/userApis";
import { Button, Grid, Paper, Stack, Typography } from "@mui/material";
import UserTableComponent from "../../../components/admin/user-manager/UserTable";
import { UserAddDrawer } from "../../../components/admin/user-manager/UserAddDrawer";
import { SearchRecords } from "../../../components/Common/SearchRecords";
import { Add } from "@mui/icons-material";

const UserManagerContainer = (props) => {
    const { showDrawer, formType, initialValues, pagination } = props;

    const { isLoading, data: users, isFetching } = useGetUserListQuery(pagination);

    const handleAddUser = () => {
        props.changeUserInitialState({
            showDrawer: true,
            formType: "Add"
        })
    }

    useEffect(() => {
        props.handlePaginationState({
            pageNo: 0,
            limit: 10,
            sortBy: -1,
            sortField: "createdAt",
            keywords: ""
        })
    }, []);

    const handlePagination = (setting) => {
        props.handlePaginationState(setting);
    }

    const handleDrawerClose = () => {
        props.changeUserInitialState({ showDrawer: false, formType: "" })
    }

    const handleUserEdit = (data, type) => {
        props.changeUserInitialState({ showDrawer: true, formType: type, initialValues: data })
    }

    return (
        <Stack spacing={1}>
            <Paper sx={{ marginBottom: "24px", padding: 1.5 }}>
                <Grid container justifyContent="space-between">
                    <Grid item sx={{ alignSelf: "center" }}>
                        <Typography variant="h6">List of Users</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            disableElevation
                            variant="contained"
                            sx={{ borderRadius: "50px" }}
                            onClick={() => handleAddUser()}
                            startIcon={<Add />}
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>
            </Paper>

            <Grid container>
                <SearchRecords handlePagination={handlePagination} pagination={pagination} />
                <Grid item xs={12}>
                    <Paper
                        sx={{ p: 2, color: "#071B2A", fontWeight: "400" }}
                        elevation={0}
                    >
                        <UserTableComponent fetching={isFetching} edit={(val, type) => handleUserEdit(val, type)} loading={isLoading} count={users?.count || 0} data={users?.result || []} pagination={pagination} handlePagination={(val) => handlePagination(val)} />
                    </Paper>
                </Grid>
            </Grid>

            {/* add taf drawer */}
            <UserAddDrawer show={showDrawer} close={handleDrawerClose} formType={formType} initialValues={initialValues} />

        </Stack >
    );
}

export default UserManagerContainer;