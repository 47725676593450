import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { changeCandidateInitialState, handleNormalPaginationState } from "../../../store/candidate/candidateSlice";
import sansthaApis from "../../../store/sanstha/sansthaApis";

const mapStateToProps = (state) => {
    return {
        formType: state.candidate.formType,
        initialValues: state.candidate.initialValues,
        showDrawer: state.candidate.showDrawer,
        normalPagination: state.candidate.normalPagination,
    };
};

const mapDispatch = {
    changeCandidateInitialState,
    handleNormalPaginationState,
    getSansthaList: sansthaApis.endpoints.getSansthaList.initiate,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(mapDispatch, dispatch);

const Store = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;