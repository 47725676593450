import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { CandidateSearchComponent } from "../../../components/admin/candidate-manager/CandidateSearchComponent";
import { useSearchCandidateQuery } from "../../../store/candidate/candidateApis";
import { setSnackBar } from "../../../store/common/commonSlice";
import { useDispatch } from "react-redux";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CandidateCards from "../../../components/admin/candidate-manager/CandidateCards";
import { CandidateAddDrawer } from "../../../components/admin/candidate-manager/CandidateAddDrawer";

const AdvanceSearchContainer = (props) => {
    const dispatch = useDispatch();
    const { advanceSearchInitialVal } = props;
    const [searchCandidate, setCandidateSearch] = useState(false);
    const { showDrawer, formType, initialValues } = props;
    const { data: candidateData, refetch, isFetching, isLoading } = useSearchCandidateQuery(advanceSearchInitialVal, { skip: !searchCandidate });
    const [expanded, setExpanded] = React.useState(true);

    const handleCandidateSearch = async (data) => {
        try {
            data = {
                ...data,
                pageNo: 0,
                limit: 12,
                sortBy: 1,
                sortField: "createdAt",
                keywords: ""
            }
            props.changeAdvanceSearchInitialState({ initialValues: data })
            if (searchCandidate) {
                refetch();
            } else {
                setCandidateSearch(true);
            }
            setExpanded(false);
        } catch (error) {
            dispatch(setSnackBar({
                open: true,
                message: error?.data?.message || error.message,
                severity: "error",
            }))
        }
    }

    const handlePagination = (data) => {
        props.changeAdvanceSearchInitialState({ initialValues: data });
        // refetch();
    }

    const handleCandidateEdit = (data, type) => {
        props.changeCandidateInitialState({ showDrawer: true, formType: "View", initialValues: data })
    }

    const handleDrawerClose = () => {
        props.changeCandidateInitialState({ showDrawer: false, formType: "", initialValues: null })
    }

    return (
        <Stack spacing={1}>
            <Paper sx={{ marginBottom: "24px", padding: 1.5 }}>
                <Grid container justifyContent="space-between">
                    <Grid item sx={{ alignSelf: "center" }}>
                        <Typography variant="h6">Search / Match Candidate</Typography>
                    </Grid>
                </Grid>
            </Paper>
            <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant={"h6"}>
                        Search here <span style={{ color: "gray", fontSize: "18px", marginLeft: 2 }}>{candidateData?.count ? `Total Count: ${candidateData?.count}` : null}</span>
                    </Typography>

                </AccordionSummary>
                <AccordionDetails>
                    <Paper
                        elevation={0}
                    >
                        <CandidateSearchComponent search={(d) => handleCandidateSearch(d)} initialValues={advanceSearchInitialVal} advance={true} />
                    </Paper>
                </AccordionDetails>
            </Accordion>

            <Grid container columns={{ xs: 4, sm: 6, md: 12 }}>
                <CandidateCards loading={isLoading || isFetching} count={candidateData?.count || 0} data={candidateData?.result || []} pagination={advanceSearchInitialVal} handlePagination={(val) => handlePagination(val)} view={(val) => handleCandidateEdit(val, "View")} />
            </Grid>

            <CandidateAddDrawer show={showDrawer} close={handleDrawerClose} formType={formType} initialValues={initialValues} isSearched={true} />

        </Stack >
    );
}

export default AdvanceSearchContainer;