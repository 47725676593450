import React from "react";
import EyeIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import { TableCell, TableRow, Typography } from "@mui/material";
import IconButtonIcons from "../../Common/IconButtonIcons";
import TableCommon from "../../Common/Table";
import { handleDateTimeDefault } from "../../../helpers/utils";
import { useDeleteUserMutation } from "../../../store/user/userApis";

const UserTableComponent = ({ loading, count, data, edit, pagination, handlePagination, fetching = false }) => {
    const [handleDelete] = useDeleteUserMutation();
    let columns = [
        {
            title: "Sr. No.",
            sort: false
        },
        {
            title: "Sanstha",
            sort: false,
            minWidth: 170,
        },
        {
            title: "Name",
            field: "name",
            sort: true
        },
        {
            title: "Email",
            field: "email",
            sort: true
        },
        {
            title: "Mobile",
            field: "mobile",
            sort: true
        },
        {
            title: "Roles",
            sort: false
        },
        {
            title: "Created At",
            field: "createdAt",
            sort: true
        },
        {
            title: "Action",
            name: "",
            sort: false
        },
    ];

    const deleteManage = (_id, converted) => {
        if (window.confirm("Are you sure you want to delete?")) {
            handleDelete({ _id });
        }
    }

    const renderTableData = !loading && data && data.length > 0 ? (
        data.map(function (row, index) {
            return (
                <TableRow
                    key={index}
                    sx={{ "td, th": { border: 0, padding: "10px" } }}
                >
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{row?.sanstha_id && row?.sanstha_id.length > 0 ? row?.sanstha_id.map((obj) => obj.name).join(", ") : (row?.sanstha_id?.name || "-")}</TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row?.email}</TableCell>
                    <TableCell align="center">{row?.mobile}</TableCell>
                    <TableCell align="center">
                        <Typography variant="p">{row.roles}</Typography>
                    </TableCell>
                    <TableCell align="center">{handleDateTimeDefault(row?.createdAt)}</TableCell>
                    <TableCell align="center" component="th" scope="row">
                        <IconButtonIcons
                            title="Edit"
                            IconComponent={EditIcon}
                            color="info"
                            onClick={() => edit(row, "Edit")}
                        />
                        <IconButtonIcons
                            title="View"
                            IconComponent={EyeIcon}
                            color="info"
                            onClick={() => edit(row, "View")}
                        />
                        <IconButtonIcons
                            title="Delete"
                            IconComponent={DeleteIcon}
                            color="info"
                            onClick={() => deleteManage(row._id)}
                        />
                    </TableCell>
                </TableRow>
            )
        })
    ) : (
        <TableRow sx={{ "td, th": { border: 0, padding: "10px" } }}>
            <TableCell colSpan={6} align="center">Data not found</TableCell>
        </TableRow>
    );

    return (
        <TableCommon columns={columns} tableData={renderTableData} count={count} loading={loading || fetching} pagination={pagination} handlePagination={handlePagination} />
    )
}

export default UserTableComponent;