import React, { Fragment, useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardContent, Divider, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import MembersTableComponent from "../../../components/admin/members-manager/MembersTable";
import { MembersAddDrawer } from "../../../components/admin/members-manager/MembersAddDrawer";
import { useGetMembersListQuery } from "../../../store/members/membersApis";
import { useConvertToUserMutation } from "../../../store/user/userApis";
import { setSnackBar } from "../../../store/common/commonSlice";
import { useDispatch } from "react-redux";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BulkImportMembers } from "../../../components/admin/members-manager/BulkImportMembers";
import { isAuth } from "../../../helpers/cookies";
import { debounce } from "../../../helpers/utils";
import { Add } from "@mui/icons-material";
import { useGetDropdownSansthaListQuery } from "../../../store/sanstha/sansthaApis";
import { useGetDashboardCountQuery } from "../../../store/common/commonApis";
import { CardSummary } from "../../../components/Common/CardSummary";
import AutoCompleteSelect from "../../../components/Common/AutoCompleteSelect";
import BasicSelect from "../../../components/Common/Select";
import { memberStatus } from "../../../helpers/sampleData";
import { memberDefaultFormValue } from "../../../store/members/membersSlice";

const MembersManagerContainer = (props) => {
    const dispatch = useDispatch();
    const { showDrawer, formType, initialValues, pagination } = props;
    const [convertToUser, { isLoading: convertUserLoading }] = useConvertToUserMutation();
    const [filter, setFilter] = useState({});
    const { isLoading, data: members, refetch, isFetching } = useGetMembersListQuery({ ...pagination, ...filter });
    const [filterSansthaId, setFilterSansthaId] = useState([]);

    useEffect(() => {
        setFilter({ ...filter, sanstha_id: filterSansthaId.map((item) => item._id).join(",") })
    }, [filterSansthaId])

    const { data: dashboardCount } = useGetDashboardCountQuery();

    const { data: sansthaList } = useGetDropdownSansthaListQuery({ limit: 1000 });

    const handleAddMembers = () => {
        props.changeMembersInitialState({
            showDrawer: true,
            formType: "Add",
            initialValues: {
                ...memberDefaultFormValue,
                sanstha_id: isAuth().roles === "sanstha" ? isAuth().sanstha_id : null
            }
        })
    }

    useEffect(() => {
        props.handlePaginationState({
            pageNo: 0,
            limit: 10,
            sortBy: -1,
            sortField: "createdAt",
            keywords: ""
        })
    }, []);

    const handlePagination = (setting) => {
        props.handlePaginationState(setting);
    }

    const handleDrawerClose = () => {
        props.changeMembersInitialState({ showDrawer: false, formType: "", initialValues: null })
    }

    const handleMembersEdit = (data, type) => {
        let payload = { ...data };
        delete payload.createdAt;
        props.changeMembersInitialState({ showDrawer: true, formType: type, initialValues: payload })
    }

    const handleSearch = (val) => {
        let keywords = val.toLowerCase();
        handlePagination({
            ...pagination,
            keywords: keywords
        })
    }

    const processChange = debounce((e) => handleSearch(e));

    const handleMemberToUserConvert = async (id) => {
        try {
            let payload = {
                type: "members",
                _id: id
            }
            await convertToUser(payload).unwrap();
            refetch();
            dispatch(setSnackBar({
                open: true,
                message: "User converted successfully",
                severity: "success",
            }))
        } catch (error) {
            dispatch(setSnackBar({
                open: true,
                message: error?.data?.message || error.message,
                severity: "error",
            }))
        }
    }

    return (
        <Stack spacing={1}>
            {
                isAuth().roles === "admin"
                    ?
                    (
                        <Grid container gap={2}>
                            <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
                                <Card>
                                    <CardContent>
                                        <Typography
                                            gutterBottom
                                            color="textPrimary"
                                        >
                                            Total Number of Sanstha
                                        </Typography>
                                        <Divider />
                                        <Typography variant="h6" color="textPrimary">
                                            {dashboardCount?.sanstha || 0}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
                                <Card>
                                    <CardContent>
                                        <Typography
                                            gutterBottom
                                            color="textPrimary"
                                        >
                                            Total Number of Members
                                        </Typography>
                                        <Divider />
                                        <Typography variant="h6" color="textPrimary">
                                            {dashboardCount?.member || 0}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    ) : null
            }
            <Paper sx={{ marginBottom: "24px", padding: 1.5 }}>
                <Grid container justifyContent="space-between">
                    <Grid item sx={{ alignSelf: "center" }}>
                        <Typography variant="h6">List of Members</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            disableElevation
                            variant="contained"
                            sx={{ borderRadius: "50px" }}
                            onClick={() => handleAddMembers()}
                            startIcon={<Add />}
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            {
                isAuth().roles === "admin"
                    ?
                    <Paper sx={{ marginBottom: "24px !important", padding: 1.5, mb: 0 }}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{ minHeight: "fit-content", pl: 0 }}
                            >
                                <Typography variant="h6">Import Bulk Members</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <BulkImportMembers sansthaList={sansthaList} />
                            </AccordionDetails>
                        </Accordion>
                    </Paper>
                    :
                    null
            }

            <Grid container>
                <Grid item xs={12} textAlign={"right"} pb={2} pt={1} display={"flex"} justifyContent={"space-between"} flexDirection={{ xs: "column", sm: "row" }} gap={2}>
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={1} gap={2}>
                        {
                            isAuth().roles === "admin" ?
                                <Fragment>
                                    <AutoCompleteSelect
                                        label="Filter by sanstha"
                                        fullWidth={false}
                                        options={sansthaList !== undefined ? sansthaList : []}
                                        onChange={(e, val) => setFilterSansthaId(val)}
                                        value={filterSansthaId}
                                        isMultiple={true}
                                        sx={{ minWidth: { xs: "100%", sm: "300px" } }}
                                    />
                                    <BasicSelect
                                        size="small"
                                        value={filter?.member_status || "all"}
                                        onChange={(e) => setFilter({ ...filter, member_status: e.target.value === "all" ? "" : e.target.value })}
                                        displayEmpty
                                        label="Filter by Member Status"
                                        items={[
                                            { label: "All", value: "all" },
                                            ...memberStatus
                                        ]}
                                        sx={{ minWidth: { xs: "100%", sm: "300px" }, textAlign: 'left' }}
                                    />
                                </Fragment>
                                : null
                        }

                    </Stack>
                    <TextField
                        size="small"
                        id="outlined-search"
                        label="Search here..."
                        type="search"
                        sx={{ width: { xs: "100%", sm: "25%" } }}
                        onChange={(e) => processChange(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Paper
                        sx={{ p: 2, color: "#071B2A", fontWeight: "400" }}
                        elevation={0}
                    >
                        <MembersTableComponent sansthaList={sansthaList} edit={(val, type) => handleMembersEdit(val, type)} convert={(id) => handleMemberToUserConvert(id)} loading={isLoading || convertUserLoading} fetching={isFetching} count={members?.count || 0} data={members?.result || []} pagination={pagination} handlePagination={(val) => handlePagination(val)} />
                    </Paper>
                </Grid>
            </Grid>

            {/* add taf drawer */}
            <MembersAddDrawer getSansthaList={props.getSansthaList} show={showDrawer} close={handleDrawerClose} formType={formType} initialValues={initialValues} />

        </Stack >
    );
}

export default MembersManagerContainer;